import React, { useEffect, useState } from 'react'
import { Outlet, useLocation, useSearchParams } from 'react-router-dom'
import Footer from '../Common/Footer/Footer';
import DesktopHeader from '../Common/Header/DesktopHeader';
import MobileHeader from '../Common/Header/MobileHeader';
import { setLocation } from '../../redux/actions/LocationAction';
import { useDispatch, useSelector } from 'react-redux';
import { getCityName } from '../../services/googleMaps';
import { toast } from 'sonner';
import { getUnreadMessageCount } from '../../services/user/conversation';
import { setChatCount } from '../../redux/actions/ChatCountAction';
import MobileNavigation from '../Common/Header/MobileNavigation';

const Template = () => {

  const { pathname } = useLocation();

  let [searchParams, setSearchParams] = useSearchParams();

  const [topPadding, setTopPadding] = useState(0);
  const [bottomPadding, setBottomPadding] = useState(0);

  const dispatch = useDispatch();

  useEffect(() => {
    let height = document.getElementById('desktop-header').clientHeight;
    if (height == 0) {
      height = document.getElementById('mobile-header').clientHeight;
    }
    let mobileNavigationHeight = document.getElementById('mobile-navigation').clientHeight;
    setBottomPadding(mobileNavigationHeight);
    setTopPadding(height);
  }, []);

  useEffect(() => {
    window.scroll(0, 0);
    if (pathname.includes('chats/') || pathname.includes('post-item')) {
      setBottomPadding(0);
    }
  }, [pathname])

  const fetchCity = async (latitude, longitude) => {
    const data = await getCityName(latitude, longitude);
    const result = await data;
    localStorage.setItem('location', JSON.stringify({
      latitude: latitude,
      longitude: longitude,
      locality: result?.localityName,
      postalCode: result?.postalCode,
      city: result?.cityName,
      state: result?.state,
      country: result?.countryName,
      locationDisabled: true
    }));
    dispatch(setLocation({
      latitude: latitude,
      longitude: longitude,
      locality: result?.localityName,
      postalCode: result?.postalCode,
      city: result?.cityName,
      state: result?.state,
      country: result?.countryName,
      locationDisabled: true
    }));
  }

  const fetchUnreadMessageCount = async () => {
    const data = await getUnreadMessageCount();
    if (await data.status) {
      dispatch(setChatCount(data.data.unread_message_count));
    }
  }

  const setDefaultLocation = () => {
    localStorage.setItem('location', JSON.stringify({
      latitude: null,
      longitude: null,
      locality: null,
      postalCode: null,
      city: null,
      state: null,
      country: 'India',
      locationDisabled: false
    }));
    dispatch(setLocation({
      latitude: null,
      longitude: null,
      locality: null,
      postalCode: null,
      city: null,
      state: null,
      country: 'India',
      locationDisabled: false
    }));
  }

  const fetchLocation = () => {
    if ("geolocation" in navigator) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          fetchCity(position.coords.latitude, position.coords.longitude);
        },
        (error) => {
          setDefaultLocation();
        }
      );
    } else {
      setDefaultLocation();
    }
  } 

  useEffect(() => {
    fetchUnreadMessageCount();
    if (localStorage.getItem('location')) {
      const location_data = JSON.parse(localStorage.getItem('location'));
      if (location_data.city === null || location_data.city === "") {
        fetchLocation();
      }
      else {
        dispatch(setLocation(JSON.parse(localStorage.getItem('location'))));
      }
    }
    else {
      fetchLocation();
    }
  }, []); 

  return (
    <React.Fragment>
      <DesktopHeader/>
      <MobileHeader/>
      <main style={{ paddingTop: `${topPadding}px`, paddingBottom: `${bottomPadding}px` }}>
        <Outlet />
      </main>
      <div className='lg:block md:block sm:hidden'>
        <Footer/>
      </div>
      <div className='lg:hidden md:hidden sm:block'>
        {(pathname.includes('chats/') || pathname.includes('post-item')) ? "" :<MobileNavigation/>}
      </div>
    </React.Fragment>
  )
}

export default Template;
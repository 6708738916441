import React, { useState } from 'react'
import { Popover, Transition } from '@headlessui/react'
import { FaMapPin } from "react-icons/fa6";
import { MdMyLocation } from "react-icons/md";
import { HiOutlineLocationMarker } from "react-icons/hi";
import { useDispatch, useSelector } from 'react-redux';
import { getCityName } from '../../../services/googleMaps';
import { setLocation } from '../../../redux/actions/LocationAction';
import { toast } from 'sonner';
import GoogleMapsAutocomplete from '../../FormControls/GooglePlacesAutocomplete/GooglePlacesAutocomplete';

const HeaderCitiesDropdown = () => {

  const location = useSelector((state) => state.LocationReducer);

  const dispatch = useDispatch();

  const [cityList, setCityList] = useState([
    {
      city: 'Mumbai',
      countty: 'India'
    },
    {
      city: 'Delhi',
      countty: 'India'
    },
    {
      city: 'Banglore',
      countty: 'India'
    },
    {
      city: 'Hyderabad',
      countty: 'India'
    }
  ]);

  const fetchCity = async (latitude, longitude) => {
    const data = await getCityName(latitude, longitude);
    const result = await data;
    localStorage.setItem('location', JSON.stringify({
      latitude: latitude,
      longitude: longitude,
      locality: result?.localityName,
      postalCode: result?.postalCode,
      city: result?.cityName,
      state: result?.state,
      country: result?.countryName,
      locationDisabled: true,
      placeId: result?.placeId
    }));
    dispatch(setLocation({
      latitude: latitude,
      longitude: longitude,
      locality: result?.localityName,
      postalCode: result?.postalCode,
      city: result?.cityName,
      state: result?.state,
      country: result?.countryName,
      locationDisabled: true,
      placeId: result?.placeId
    }));
  }

  const setDefaultLocation = () => {
    localStorage.setItem('location', JSON.stringify({
      latitude: null,
      longitude: null,
      locality: null,
      postalCode: null,
      city: null,
      state: null,
      country: 'India',
      locationDisabled: false,
      placeId: null
    }));
    dispatch(setLocation({
      latitude: null,
      longitude: null,
      locality: null,
      postalCode: null,
      city: null,
      state: null,
      country: 'India',
      locationDisabled: false,
      placeId: null
    }));
  }

  const fetchLocation = async () => {
    if ("geolocation" in navigator) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          fetchCity(position.coords.latitude, position.coords.longitude, true);
        },
        (error) => {
          toast.error('Please allow location service');
          setDefaultLocation();
        }
      );
    } else {
      toast.error('Please allow location service');
      setDefaultLocation();
    }
  }

  return (
    <React.Fragment>
      <div>
        <Popover className="relative">
          <Popover.Button className={'outline-none'}>
            <button className='border bg-slate-50 lg:py-2.5 md:py-2.5 sm:py-1.5 lg:px-4 md:px-2 sm:px-2 flex items-center justify-between lg:rounded-full sm:rounded-md lg:space-x-1.5 sm:space-x-1 shadow-sm'>
              <FaMapPin size={12} />
              <span className='lg:text-xs md:text-xs sm:text-[0.62rem] font-medium'>{ ((location?.locality === null) && (location?.city === null)) ? location?.country : (location.locality !== null) ? location?.locality + ', ' : ''} {(location.city !== null) ? location?.city : '' }</span>
            </button>
          </Popover.Button>

          <Transition
            enter="transition duration-100 ease-out"
            enterFrom="transform scale-95 opacity-0"
            enterTo="transform scale-100 opacity-100"
            leave="transition duration-75 ease-out"
            leaveFrom="transform scale-100 opacity-100"
            leaveTo="transform scale-95 opacity-0"
          >
            <Popover.Panel className="absolute lg:left-0 sm:right-0 z-10 w-fit mt-2">
              <div className='shadow-xl p-2 bg-white border rounded-lg space-y-2 min-w-[250px]'>
                <div>
                  <button onClick={() => fetchLocation()} className={`flex items-center justify-start gap-2 p-3 rounded-md w-full ${location.locationDisabled ? 'bg-ascent' : 'bg-red-500'} bg-opacity-10`}>
                    <MdMyLocation size={22} />
                    {location?.city !== null}
                    <div className='text-left space-y-0.5'>
                      <p className='whitespace-nowrap text-xs font-medium'>{location.locationDisabled ? `${(location.locality !== null) ? location?.locality + ', ' : ''} ${(location.city !== null) ? location?.city + ', ' : ''} ${location.country}` : 'Use current location'}  </p>
                      <p className='whitespace-nowrap text-[0.6rem] text-gray-700'>{location.locationDisabled ? 'Using your current location' : 'Location blocked check browser settings'} </p>
                    </div>
                  </button>
                </div>
                <div className='space-y-2'>
                  <GoogleMapsAutocomplete/>
                  {/* <div className='border flex items-center justify-center rounded-lg overflow-clip bg-slate-100'>
                    <input type="search" placeholder='Search City' className='w-full px-4 py-2 outline-none text-xs' />
                  </div> */}
                  <div className='space-y-2 p-1'>
                    <p className='text-xs text-gray-500'>Polular Cities</p>
                    <ul className='space-y-2'>
                      {cityList.map((item, index) => <li key={index} className='flex items-center justify-start text-xs gap-1'>
                        <HiOutlineLocationMarker />
                        <span>{item.city}, {item.countty}</span>
                      </li>)}
                    </ul>
                  </div>
                </div>
              </div>
            </Popover.Panel>
          </Transition>
        </Popover>
      </div>
    </React.Fragment>
  )
}

export default HeaderCitiesDropdown

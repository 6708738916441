import { GoogleMap, useLoadScript } from '@react-google-maps/api';

export const getCityName = async (lat, lng) => {
    const url = `https://maps.googleapis.com/maps/api/geocode/json?latlng=${lat},${lng}&key=${process.env.REACT_APP_GOOGLE_MAPS_API_KEY}`;

    try {
        const response = await fetch(url);
        const data = await response.json();

        if (data.status === 'OK') {
            const results = data.results;
            
            let cityName = null;
            let countryName = null;
            let localityName = null;
            let postalCode = null;
            let state = null;
            let placeId = null;

            if (results[0]) {
                placeId = results[0]?.place_id;
            }

            for (let i = 0; i < results.length; i++) {
                const addressComponents = results[i].address_components;
                for (let j = 0; j < addressComponents.length; j++) {
                    const types = addressComponents[j].types;
                    if (types.includes('locality')) {
                        cityName = addressComponents[j].long_name;
                    }
                    if (types.includes('country')) {
                        countryName = addressComponents[j].long_name;
                    }
                    if (types.includes('sublocality')) {
                        localityName = addressComponents[j].long_name;
                    }
                    if (types.includes('postal_code')) {
                        postalCode = addressComponents[j].long_name;
                    }
                    if (types.includes('administrative_area_level_1')) {
                        state = addressComponents[j].long_name;
                    }
                }
            }

            return { cityName, countryName, localityName, postalCode, state, placeId };
        } else {
            console.error('Geocoding API error:', data.status);
        }
    } catch (error) {
        console.error('Error fetching data from Geocoding API:', error);
    }

    return { cityName: null, countryName: null, localityName: null, postalCode: null, state: null, placeId: null };
}

export const getLocationCoordinates = () => {
    if ("geolocation" in navigator) {
        navigator.geolocation.getCurrentPosition(
            (position) => {
                return {
                    latitude: position.coords.latitude,
                    longitude: position.coords.longitude
                }
            },
            (error) => {
                console.log(error);
                return null;
            }
        );
    } else {
        console.log("Geolocation is not supported by this browser.");
        return null;
    }
} 
import axios from "axios"

export const userLogin = async (data) => {
    try {
        
        const response = axios.post(`${process.env.REACT_APP_API_BASE_URL}/user/login`, data );
        return (await response).data;
        
    } catch (error) {
        return false;
    }
}

export const userRegister = async (data) => {
    try {
        
        const response = axios.post(`${process.env.REACT_APP_API_BASE_URL}/user/register`, data );
        return (await response).data;
        
    } catch (error) {
        return false;
    }
}

export const userGoogleAuth = async (data) => {
    try {
        
        const response = axios.post(`${process.env.REACT_APP_API_BASE_URL}/user/auth/google`, data );
        return (await response).data;
        
    } catch (error) {
        return false;
    }
}

export const sendPasswordResetOTP = async (data) => {
    try {
        
        const response = axios.post(`${process.env.REACT_APP_API_BASE_URL}/user/password/send-otp`, data );
        return (await response).data;
        
    } catch (error) {
        return false;
    }
}

export const resetPassword = async (data) => {
    try {
        
        const response = axios.post(`${process.env.REACT_APP_API_BASE_URL}/user/password/reset`, data );
        return (await response).data;
        
    } catch (error) {
        return false;
    }
}
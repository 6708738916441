import axios from "axios"

export const saveWishlist = async (id) => {
    try {
        
        const response = axios.get(`${process.env.REACT_APP_API_BASE_URL}/user/wishlist/save/${id}`, {
            headers: {
              "Authorization": `Bearer ${localStorage.getItem('accessToken')}`
            }
        });
        return (await response).data;
        
    } catch (error) {
        return false;
    }
}

export const getWishlist = async () => {
    try {
        
        const response = axios.get(`${process.env.REACT_APP_API_BASE_URL}/user/wishlist/item`, {
            headers: {
              "Authorization": `Bearer ${localStorage.getItem('accessToken')}`
            }
        });
        return (await response).data;
        
    } catch (error) {
        return false;
    }
}
import { Link } from 'react-router-dom';
import React from 'react';
import { IoChevronForward } from "react-icons/io5";

const Breadcrumbs = ({ crumbs }) => {
  return (
    <React.Fragment>
      <div className='bg-gray-50 border-b border-b-gray-100'>
        <div className='container'>

          <div className='lg:py-5 sm:py-4 overflow-x-scroll'>
            <ul className='font-medium opacity-70 lg:text-sm md:text-sm sm:text-xs flex items-center space-x-1.5'>
              <li><Link to={"/"}>Home</Link></li>
              {crumbs.map((item, index) => 
                <React.Fragment key={index}>
                  <li><IoChevronForward size={14} /></li>
                  <li><Link className={item.active ? 'text-ascent-dark' : 'text-black'} to={item.path}>{item.label}</Link></li>
                </React.Fragment>
              )}
            </ul>
          </div>

        </div>
      </div>
    </React.Fragment>
  )
}

export default Breadcrumbs
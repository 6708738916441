import { LOCATION_SET, LOCATION_UNSET } from "../constants/LocationConstant";

const defaultState = {
    latitude: null,
    longitude: null,
    locality: null,
    postalCode: null,
    city: null,
    state: null,
    country: 'India',
    locationDisabled: false,
    placeId: null,
};

const LocationReducer = (state = defaultState, action) => {
    switch (action.type) {
        case LOCATION_SET: {
            state = action.payload;
            return state;
        }
        case LOCATION_UNSET: {
            state = defaultState;
            return state;
        }
        default:
            return state;
    }
}

export default LocationReducer;
import React from 'react';
import { TbMail, TbPhone, TbEdit } from 'react-icons/tb';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { FiExternalLink, FiInfo } from 'react-icons/fi';

const Account = () => {

  const auth = useSelector((state) => state.AuthReducer);

  if (!auth) {
    return "Loading";
  }

  return (
    <React.Fragment>
      <figure className="bg-white border shadow rounded-lg overflow-clip">
        <div className="bg-ascent md:px-20 sm:px-7 relative py-20 overflow-visible" style={{ backgroundImage: "url('/assets/Dashboard/account-bg.svg')" }}>
          <img src={`${process.env.REACT_APP_STORAGE_BASE_URL}/${auth.profile_image}`} alt="profile-img" className="h-[100px] w-[100px] rounded-full border-[6px] border-white absolute z-20 -bottom-1/3 bg-white ring ring-slate-600" />
        </div>
        <div className="md:px-20 sm:px-7 lg:pt-20 md:pt-20 sm:pt-[70px] lg:pb-16 md:pb-10 sm:pb-8 lg:space-y-5 md:space-y-4 sm:space-y-3">
          <div className='space-y-2'>
            <h1 className="text-xl font-medium">{auth.name}</h1>
            <p className="text-gray-500 text-xs flex items-center"><TbMail size={14} className="mr-1 mb-[1px]" />{auth.email}</p>
            
          </div>
          { (auth.email_verified_at == null) && 
              <div className='alert-warning-md w-fit flex lg:flex-row md:flex-row sm:flex-col gap-1 justify-center lg:items-center sm:items-start'>
                <div className='flex items-center justify-center gap-1'>
                  <FiInfo size={12} strokeWidth={3} />
                  <p className='text-left'>Your email address is not verified yet</p>
                  </div>
                <Link to={'/dashboard/verify-email'} className='font-medium text-xs text-ascent-dark flex items-center justify-center gap-1'>Verify Now <FiExternalLink size={12} strokeWidth={3} /></Link>
              </div>}
          <div>
            <Link to={"/dashboard/setting"}><button className="btn-primary-sm flex items-center justify-center"><TbEdit size={16} className="mb-[1px] mr-1" /> Edit Information</button></Link>
          </div>
        </div>
      </figure>
    </React.Fragment>
  )
}

export default Account
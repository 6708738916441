import axios from "axios";

export const createPost = async (data) => {
    try {
        
        const response = axios.post(`${process.env.REACT_APP_API_BASE_URL}/user/post/create`, data, {
            headers: {
              "Content-Type": 'multipart/form-data',
              "Authorization": `Bearer ${localStorage.getItem('accessToken')}`
            }
        });
        return (await response).data;
        
    } catch (error) {
        return false;
    }
}

export const getAllPost = async () => {
    try {
        
        const response = axios.get(`${process.env.REACT_APP_API_BASE_URL}/user/post`, {
            headers: {
              "Content-Type": 'multipart/form-data',
              "Authorization": `Bearer ${localStorage.getItem('accessToken')}`
            }
        });
        return (await response).data;
        
    } catch (error) {
        return false;
    }
}

export const getIndividualPost = async (id) => {
    try {
        
        const response = axios.get(`${process.env.REACT_APP_API_BASE_URL}/user/post/${id}`, {
            headers: {
              "Content-Type": 'multipart/form-data',
              "Authorization": `Bearer ${localStorage.getItem('accessToken')}`
            }
        });
        return (await response).data;
        
    } catch (error) {
        return false;
    }
}

export const updatePost = async (data, id) => {
    try {
        
        const response = axios.post(`${process.env.REACT_APP_API_BASE_URL}/user/post/update/${id}`, data, {
            headers: {
              "Content-Type": 'multipart/form-data',
              "Authorization": `Bearer ${localStorage.getItem('accessToken')}`
            }
        });
        return (await response).data;
        
    } catch (error) {
        return false;
    }
}

export const deletePostMedia = async (id) => {
    try {
        
        const response = axios.get(`${process.env.REACT_APP_API_BASE_URL}/user/post/media/delete/${id}`, {
            headers: {
              "Content-Type": 'multipart/form-data',
              "Authorization": `Bearer ${localStorage.getItem('accessToken')}`
            }
        });
        return (await response).data;
        
    } catch (error) {
        return false;
    }
}

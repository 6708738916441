import React from 'react'
import { Outlet, useLocation } from 'react-router-dom'
import DashboardSidebar from '../Common/DashboardSidebar/DashboardSidebar'
import Breadcrumbs from '../Breadcrumbs/Breadcrumbs';

const Dashboard = () => {

  const { pathname } = useLocation();

  const crumbs = [
    {
      label: "Dashboard",
      path: "/dashboard/account",
      active: false
    },
    {
      label: "Setting",
      path: "/dashboard/setting",
      active: false
    },
    {
      label: "Email Verification",
      path: "/dashboard/verify-email",
      active: false
    },
    {
      label: "My Posts",
      path: "/dashboard/post",
      active: false
    },
    {
      label: "Wishlist",
      path: "/dashboard/wishlist",
      active: false
    },
    {
      label: "Chats",
      path: "/chats",
      active: false
    }
  ];

  return (
    <React.Fragment>
      <Breadcrumbs crumbs={[{
      label: "Dashboard",
      path: "/dashboard/account",
      active: false
    }]} />
      <section>
        <div className='container lg:py-10 md:py-8 sm:py-5 lg:space-y-7 sm:space-y-5'>
          <div>
            <h1 className='font-semibold lg:text-3xl sm:text-2xl'>
              {crumbs.map(({ path, label }) => (pathname.includes(path)) && label )}
            </h1>
          </div>
          <div className='grid lg:grid-cols-10 md:grid-cols-6 sm:grid-cols-1 gap-10'>
            <div className='lg:col-span-2 md:col-span-2 sm:col-span-1 lg:block md:block sm:hidden'>
              <DashboardSidebar />
            </div>
            <div className='lg:col-span-8 md:col-span-4 sm:col-span-1'>
              <Outlet />
            </div>
          </div>
        </div>
      </section>
    </React.Fragment>
  )
}

export default Dashboard
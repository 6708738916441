import React from 'react';
import { FiSearch } from 'react-icons/fi';
import Typewriter from 'typewriter-effect';

const Intro = () => {

  const top_cities = ['Mumbai', 'Pune', 'Banglore', 'Hyderabad', 'Delhi', 'Noida', 'Nagpur']

  return (
    <React.Fragment>
      <section className='bg-complement'>
        <div className='container lg:py-32 md:py-32 sm:py-16'>

          <div className='grid lg:grid-cols-2 md:grid-cols-2 sm:grid-cols-1 gap-10'>

            <figure className='space-y-4'>
              <div className='lg:text-left sm:text-center space-y-4'>
                <div className='space-y-3'>
                  <h1 className='lg:text-3xl md:text-3xl sm:text-2xl font-bold text-slate-900'>India's Best Platform </h1>
                  <h1 className='text-ascent lg:text-5xl md:text-5xl sm:text-3xl font-bold flex items-center lg:justify-start sm:justify-center gap-2.5'>
                    <span className='text-slate-900'>For Selling </span>  <Typewriter
                      options={{
                        strings: [' Vehicles', ' Ferniture', ' Electronics'],
                        autoStart: true,
                        loop: true,
                      }}
                    /> </h1>
                </div>
                <p className='lg:text-sm sm:text-xs text-gray-600 lg:leading-relaxed sm:leading-relaxed'>Lorem, ipsum dolor consectetur adipisiciuae qui asperiores cupiditate recusandae.</p>
              </div>
              <div className='flex items-center justify-center rounded-full overflow-clip bg-complement shadow-lg ring-2 ring-ascent-dark'>
                <input type="search" placeholder='Search Vehicles, Electronics, Ferniture, etc' className='w-full lg:px-8 lg:py-4 md:px-8 md:py-4 sm:px-6 sm:py-3 outline-none lg:text-base md:text-base sm:text-sm border-none' />
                <button className='lg:px-5 lg:py-4 md:px-5 md:py-4 sm:px-4 sm:py-3 bg-ascent bg-opacity-10 text-ascent-dark'><FiSearch strokeWidth={3} size={25} /> </button>
              </div>
              <div className='lg:text-left sm:text-center space-y-4'>
                <h6 className='text-slate-800 lg:text-base md:text-base sm:text-xs'>Popular Cities in India</h6>
                <div className='flex items-center lg:justify-start sm:justify-center flex-wrap gap-2'>
                  {top_cities.map((item, index) => <div key={index} className='lg:text-sm sm:text-xs px-2 py-1 bg-white shadow-sm rounded-md text-ascent-dark'>{item}</div>)}
                </div>
              </div>
            </figure>

            <figure>

            </figure>

          </div>


        </div>
      </section>
    </React.Fragment>
  )
}

export default Intro

import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { FiMessageCircle, FiSearch, FiUser } from 'react-icons/fi';
import { FaHeadset, FaInfo, FaInfoCircle, FaRegBuilding, FaUser, FaUsers, FaUserTie } from 'react-icons/fa';
import { useSelector } from 'react-redux';
import $ from 'jquery';
import { CiMobile3 } from "react-icons/ci";
import HeaderCitiesDropdown from '../../Dropdowns/HeaderCitiesDropdown/HeaderCitiesDropdown';
import { BsCartPlus } from "react-icons/bs";
import { getTopCategories } from '../../../services/guest/category';

const DesktopHeader = () => {

  const auth = useSelector((state) => state.AuthReducer);
  const chatCount = useSelector((state) => state.ChatCountReducer);

  const [categories, setCategories] = useState([]);

  const fetchCategories = async () => {
    const data = await getTopCategories();
    setCategories(await data.data);
  }

  useEffect(() => {

    $(window).scroll(function(){
      if ($(window).scrollTop() > 100) {
          $('.top-header').slideUp(250);
      }
      else {
          $('.top-header').slideDown(250);
      }
    });

    fetchCategories();

  },[]);

  return (
    <React.Fragment>
      <header className={`fixed top-0 z-50 w-full lg:block md:block sm:hidden bg-white border-b shadow`} id='desktop-header'>
        <nav className='bg-slate-900 top-header'>
          <div className='container py-2'>
            <div className='flex items-center justify-between'>
              <div>
                <p className='text-xs text-white'>Buy, sell and find just about anything using the app on your mobile.</p>
              </div>
              <div className='flex items-center justify-between gap-3'>
                <Link to={'/register'} className='text-xs text-white hover:text-ascent transition duration-300 ease-in-out hover:ease-in-out flex items-center justify-center gap-1'>
                  <FaRegBuilding size={11} />
                  <span>Register As Seller</span>
                </Link>
                <span className='text-white text-xs'>|</span>
                <Link to={'/register'} className='text-xs text-white hover:text-ascent transition duration-300 ease-in-out hover:ease-in-out flex items-center justify-center gap-1'>
                  <CiMobile3 size={16} />
                  <span>Download Our Mobile App</span>
                </Link>
              </div>
            </div>
          </div>
        </nav>
        <nav className='border-b'>
          <div className='container py-3.5'>
            <div className='grid lg:grid-cols-3 md:grid-cols-3 sm:grid-cols-1 gap-5 items-center justify-between'>

              <div className='flex items-center justify-between'>
                <Link to={'/'}>
                  <img src={'/assets/logo.png'} alt="logo" className='h-[40px] w-auto' />
                </Link>
                <div>
                  <HeaderCitiesDropdown/>
                </div>
              </div>
              

              <div className='border flex items-center justify-center rounded-full overflow-clip bg-slate-100 shadow-sm'>
                <input type="search" placeholder='Search Any Product' className='w-full px-5 py-2.5 outline-none text-xs' />
                <button className='px-4 py-2.5'><FiSearch size={15} /> </button>
              </div>

              <div className='flex items-center justify-end gap-2'>
              {auth && 
                <Link to={'/chats'} className='relative px-2 py-2 w-fit h-fit flex items-center justify-center rounded-md  text-ascent-dark border bg-ascent bg-opacity-10'>
                  <FiMessageCircle strokeWidth={2.5} size={20} />
                  { (chatCount !== 0) && <span className='h-5 w-5 text-[0.6rem] flex items-center justify-center text-white font-medium bg-ascent rounded-full absolute -top-2 -right-2'>{chatCount}</span> }
                </Link> }
                <Link to={'/post-item'} className='btn-secondary-sm flex items-center'><BsCartPlus strokeWidth={0.5} size={15} /><span className='px-2'>Start Selling</span></Link>

                {auth 
                ? 
                <Link to={'/dashboard/account'} className='btn-primary-sm flex items-center justify-center'><FiUser size={15} /><span className='px-2'>My Account</span></Link>
                : 
                <>
                  <Link to={'/login'} className='btn-primary-sm'><span className='px-2'>Login</span></Link>
                  <Link to={'/register'} className='btn-light-sm'><span className='px-2'>Register</span></Link>
                </>}

                
              </div>

            </div>
          </div>
        </nav>
        <nav>
          <div className='container py-2'>
            <div className='flex items-center justify-between'>
              <ul className='header-bottom-nav-list'>
                <li><a href="#" className='bottom-header-nav'>All Categories</a></li>
                {categories.slice(0,5).map((category) => <li key={category.id}><Link to={`/category/${category.slug}`} className='bottom-header-nav'>{category.name}</Link></li> )}
              </ul>
              <ul className='header-bottom-nav-list'>
              <li>
                <a href="#" className='bottom-header-nav'>
                  <span>About Us</span>
                </a>
              </li>
              <li>
                <a href="#" className='bottom-header-nav'>
                  <span><FaHeadset size={15} /></span>
                  <span>Talk with Customer Support</span>
                </a>
              </li>
              </ul>
            </div>
          </div>
        </nav>
      </header>
    </React.Fragment>
  );
}

export default DesktopHeader;
import { CHAT_COUNT_SET, CHAT_COUNT_UNSET } from "../constants/ChatCountConstant";

const ChatCountReducer = (state = 0, action) => {
    switch (action.type) {
        case CHAT_COUNT_SET: {
            state = action.payload;
            return state;
        }
        case CHAT_COUNT_UNSET: {
            state = 0;
            return state;
        }
        default:
            return state;
    }
}

export default ChatCountReducer;
import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { toast } from 'sonner';
import { useNavigate, useParams } from 'react-router-dom';
import { deletePostMedia, getIndividualPost, updatePost } from '../../../../services/user/post';
import { FiArrowRight, FiSearch, FiUploadCloud, FiX } from 'react-icons/fi';
import { MdMyLocation } from 'react-icons/md';
import { LuLoader } from "react-icons/lu";
import Swal from 'sweetalert2';

const PostEdit = () => {

  const auth = useSelector((state) => state.AuthReducer);
  
  const navigate = useNavigate();

  const { id } = useParams();

  const [selectedImages, setSelectedImages] = useState([]);
  const [selectedThumbnail, setSelectedThumbnail] = useState('/assets/default-thumbnail.png');
  const [formSubmitState, setFormSubmitState] = useState(false);

  const [inputFields, setInputFields] = useState({
    title: '',
    description: '',
    selling_price: '',
    is_negotiable: false,
    media: []
  });

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setInputFields({ ...inputFields, [name]: value });
  }

  const fetchIndividualPost = async (id) => {
    const response = await getIndividualPost(id);
    if (response.status) {
      const data = await response.data;
      setInputFields({
        title: data?.title,
        description: data?.description,
        selling_price: data?.selling_price,
        is_negotiable: data?.is_negotiable === "1" ? true : false,
        media: data?.media
      });
      setSelectedThumbnail(`${process.env.REACT_APP_STORAGE_BASE_URL}/${data?.thumbnail_image}`)
    }
    else {
      toast.error(response.message);
    }    
  }

  useEffect(() => {
    fetchIndividualPost(id);
  }, [id]);

  const handleSelectImages = (event) => {
    const files = event.target.files;
    const filesArray = Array.from(files);
    const filesJson = filesArray.map(file => { return file });
    if (files.length > 0) {
      setSelectedImages(filesJson);
    }
    else {
      setSelectedImages([]);
    }
  }

  const handleFormSubmit = async (event) => {
    setFormSubmitState(true);
    event.preventDefault();

    const form = new FormData(event.target);

    const data = {
      title: form.get('title'),
      description: form.get('description'),
      thumbnail_image: form.get('thumbnail_image'),
      post_media: form.getAll('post_media'),
      selling_price: form.get('selling_price'),
      is_negotiable: form.get('is_negotiable') ? 1 : 0,
    }

    const postResponse = await updatePost(data, id);
    const result = await postResponse;
    if (result.status) {
      toast.success(result.message);
      fetchIndividualPost(id);
      setFormSubmitState(false);
    }
    else {
      setFormSubmitState(false);
      toast.error(result.message);
    }
  }

  const handleDeleteMedia = async (media_id) => {
    Swal.fire({
      title: 'Are you sure?',
      text: 'Once you clicked on Delete the file will deleted',
      icon: 'question',
      showCancelButton: true,
      cancelButtonText: "Cancel",
      confirmButtonText: "Delete",
    }).then(async (result) => {
      if (result.isConfirmed) {
        const response = await deletePostMedia(media_id);
          const result = await response;
          if (result.status) {
            fetchIndividualPost(id);
            toast.success(result.message);
          }
          else {
            toast.error(result.message);
          }
      }
    });
  }

  if (!auth) {
    return "Loading";
  }

  return (
    <React.Fragment>
      <form onSubmit={(event) => handleFormSubmit(event)}>
        <figure className='lg:border lg:shadow rounded-lg overflow-clip'>
          <div className='lg:px-5 lg:py-4 sm:pt-0 sm:pb-4 space-y-1 border-b'>
            <h1 className='font-semibold'>Edit Post {inputFields?.is_negotiable}</h1>
            <p className='text-xs text-gray-500'>Change your post information</p>
          </div>
          <div className='lg:px-5 py-5 space-y-1'>
            <div className='grid grid-cols-1 gap-5'>

              <div>
                <h1 className='font-semibold'>General Information</h1>
              </div>

              <div className='input-group'>
                <label htmlFor="title" className='input-label'>Title <em>*</em></label>
                <input type="text" value={inputFields?.title} placeholder='Post Title' className='input-box-md' onChange={(event) => handleInputChange(event)} name="title" required={true} />
              </div>

              <div className='input-group'>
                <label htmlFor="description" className='input-label'>Description <em>*</em></label>
                <textarea name="description" value={inputFields?.description} rows={3} id="description" className='input-box-md' onChange={(event) => handleInputChange(event)} placeholder='Describe your product'></textarea>
              </div>

              <div className='input-group'>
                <label htmlFor="selling_price" className='input-label'>Selling Price <em>*</em></label>
                <input type="number" min={1} max={1000000000} value={inputFields?.selling_price} placeholder='Selling Price' onChange={(event) => handleInputChange(event)} className='input-box-md' name="selling_price" required={true} />
              </div>

              <div className="flex items-center">
                <input type="checkbox" onChange={(event) => handleInputChange(event)} defaultChecked={inputFields.is_negotiable} name="is_negotiable" value={1} id="is_negotiable" />
                <label htmlFor="is_negotiable" className="text-xs">Price is Negotiable</label>
              </div>

              <div>
                <h2 className='font-semibold'>Upload Photos</h2>
              </div>

              <div className='input-group'>
                <label htmlFor="images" className="input-label">Post Thumbnail <span>(Format: png, jpg, jpeg,
                  webp)</span> <em>*</em></label>

                <div className="flex lg:flex-row sm:flex-col my-2 gap-2">
                  <div className="input-box-dragable">
                    <input type="file" accept="image/jpeg, image/jpg, image/png, image/webp" name="thumbnail_image" onChange={(event) => {
                      if (event.target.files.length > 0) {
                        setSelectedThumbnail(URL.createObjectURL(event.target.files[0]));
                      }
                      else {
                        setSelectedThumbnail(`${process.env.REACT_APP_STORAGE_BASE_URL}/${inputFields?.thumbnail_image}`);
                      }
                    }} max={20} min={1} />
                    <FiUploadCloud size={30} />
                    <span>Darg and Drop Image Files</span>
                  </div>
                  <img src={selectedThumbnail} alt="thumbnail_image" className="input-thumbnail-preview" />
                </div>
              </div>

              <div className='input-group'>
                <label htmlFor="post_media" className="input-label">Select Images <span>(Format: png, jpg, jpeg,
                  webp)</span> <span>(Optional)</span></label>
                <div className="input-box-dragable">
                  <input multiple type="file" accept="image/jpeg, image/jpg, image/png, image/webp" name="post_media" onChange={(event) => handleSelectImages(event)} max={20} min={1} />
                  <FiUploadCloud size={30} />
                  <span>Darg and Drop Image Files</span>
                </div>
                <div className='grid lg:grid-cols-4 sm:grid-cols-3 gap-3 mt-3'>
                  {selectedImages.map((image, index) =>
                    <div key={index} className='h-[120px] w-full rounded border bg-slate-50 flex items-center justify-center overflow-clip'>
                      <img src={URL.createObjectURL(image)} alt="" className='h-auto w-full' />
                    </div>
                  )}
                </div>

                <div className='grid lg:grid-cols-3 sm:grid-cols-2 gap-3 mt-3'>
                  {inputFields.media.map((image, index) =>
                    <div key={index} className='h-[180px] w-full rounded border bg-slate-50 flex items-center justify-center overflow-clip relative'>
                      <button onClick={() => handleDeleteMedia(image.id)} type='button' className='absolute top-2 left-2 bg-white border shadow-sm rounded-lg'>
                        <FiX strokeWidth={3} size={20} />
                      </button>
                      <img src={`${process.env.REACT_APP_STORAGE_BASE_URL}/${image?.path}`} alt="" className='h-auto w-full' />
                    </div>
                  )}
                </div>
              </div>

            </div>
          </div>
          <div className='p-5 space-y-1 border-t'>
            <button disabled={formSubmitState} type='submit' className='btn-primary-md flex items-center justify-center gap-2 md:w-fit sm:w-full'>
              {formSubmitState && <LuLoader size={15} className='animate-spin' />}
              <span>Save Changes</span>
            </button>
          </div>
        </figure>
      </form>
    </React.Fragment>
  )
}

export default PostEdit
import axios from "axios"

export const getUserProfile = async () => {
    try {
        
        const response = axios.get(`${process.env.REACT_APP_API_BASE_URL}/user`, {
            headers: {
              "Authorization": `Bearer ${localStorage.getItem('accessToken')}`
            }
        });
        return (await response).data;
        
    } catch (error) {
        return false;
    }
}

export const userDetailsUpdate = async (data) => {
    try {
        
        const response = axios.post(`${process.env.REACT_APP_API_BASE_URL}/user/update/details`, data, {
            headers: {
              "Content-Type": 'multipart/form-data',
              "Authorization": `Bearer ${localStorage.getItem('accessToken')}`
            }
        });
        return (await response).data;
        
    } catch (error) {
        return false;
    }
}

export const userPasswordUpdate = async (data) => {
    try {
        
        const response = axios.post(`${process.env.REACT_APP_API_BASE_URL}/user/update/details`, data, {
            headers: {
              "Content-Type": 'multipart/form-data',
              "Authorization": `Bearer ${localStorage.getItem('accessToken')}`
            }
        });
        return (await response).data;
        
    } catch (error) {
        return false;
    }
}

export const sendEmailVerificationOtp = async () => {
    try {
        
        const response = axios.get(`${process.env.REACT_APP_API_BASE_URL}/user/verification/email/send-otp`, {
            headers: {
              "Content-Type": 'multipart/form-data',
              "Authorization": `Bearer ${localStorage.getItem('accessToken')}`
            }
        });
        return (await response).data;
        
    } catch (error) {
        return false;
    }
}

export const verifyEmailVerificationOtp = async (data) => {
    try {
        
        const response = axios.post(`${process.env.REACT_APP_API_BASE_URL}/user/verification/email/verify-otp`, data, {
            headers: {
              "Content-Type": 'multipart/form-data',
              "Authorization": `Bearer ${localStorage.getItem('accessToken')}`
            }
        });
        return (await response).data;
        
    } catch (error) {
        return false;
    }
}
import axios from 'axios';
import React, { useState, useEffect } from 'react';
import { BiMap, BiMailSend, BiPhoneCall, BiChevronsRight, BiMessageSquareDetail, BiHeadphone } from 'react-icons/bi';
import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';
import { FaFacebookSquare, FaTwitter, FaLinkedin, FaInstagram, FaHeadset } from "react-icons/fa";
import { RiWhatsappLine } from "react-icons/ri";
import { MdOutlineSupportAgent } from "react-icons/md";
import { FaHeadphones } from 'react-icons/fa';


const Footer = () => {

  const [email, setEmail] = useState('');

  const handleNewsletterEmailForm = async (event) => {
    event.preventDefault();
    const response = await axios.post(`${process.env.REACT_APP_API_BASE_URL}/newsletter-email`, {
      email: email
    });
    if (response.data.status) {
      setEmail('');
      toast.success(response.data.message);
    }
    else {
      toast.error(response.data.message);
    }
  }

  return (
    <React.Fragment>
      <footer className='border-t'>
        <nav className='bg-complement py-5'>
          <div className='container'>
            <div className='flex lg:flex-row sm:flex-col items-center justify-between lg:gap-7 md:gap-5 sm:gap-4'>
              <div>
                <h1 className='text-2xl font-bold'>
                    <span className='text-ascent'>Zaap</span>
                    <span className='text-ascent-dark'>Deal</span>
                </h1>
              </div>
              <div className='text-center'>
                <p className='text-black lg:text-base md:text-base sm:text-sm leading-loose font-medium'>Take a free call to our support team <a href="tel:1234567890" className='text-ascent-dark'>+91-1234567890</a></p>
              </div>
              <div>
                <Link to={'/contact'}>
                <button  className='btn-primary-md'>
                <div className='flex space-x-2 items-center px-3'>
                  <span>Customer Support</span>
                  <FaHeadset strokeWidth={2} size={20} />
                 </div>
                </button>
                 
                </Link>
              </div>
            </div>
          </div>
        </nav>
        <nav className='bg-slate-900 lg:pt-20 md:pt-20 sm:pt-12 lg:pb-16 md:pb-16 sm:pb-10'>
          <div className='container'>
            <div className='grid lg:grid-cols-4 md:grid-cols-2 sm:grid-cols-1 gap-10'>

              <div className='space-y-4'>
                <h6 className='font-semibold text-xl text-white'>About ZappDeal</h6>
                <p className='text-sm text-gray-300 leading-loose'>Lorem ipsum, dolor sit amet consectetur adipisicing elit. Placeat ipsum soluta dolor modi maiores quis dolores magni non incidunt temporibus, sapiente possimus et eaque, libero adipisci sequi autem voluptate quia.</p>
                <div>
                  <Link to={'/about'} className='text-sm text-ascent hover:text-white'>Learn more</Link>
                </div>
              </div>

              <div className='space-y-4 lg:pl-10'>
                <h6 className='font-semibold text-xl text-white'>Quick Links</h6>
                <ul className='space-y-2'>
                  <li className='flex gap-1 items-center'>
                    <BiChevronsRight size={25} className='fill-ascent' />
                    <Link to={'/careers'} className='text-white text-sm leading-loose hover:text-ascent transition duration-300 ease-in-out hover:ease-in-out'>Top Institutes</Link>
                  </li>
                  <li className='flex gap-1 items-center'>
                    <BiChevronsRight size={25} className='fill-ascent' />
                    <Link to={'/careers'} className='text-white text-sm leading-loose hover:text-ascent transition duration-300 ease-in-out hover:ease-in-out'>Top Faculties</Link>
                  </li>
                  <li className='flex gap-1 items-center'>
                    <BiChevronsRight size={25} className='fill-ascent' />
                    <Link to={'/privacy-policy'} className='text-white text-sm leading-loose hover:text-ascent transition duration-300 ease-in-out hover:ease-in-out'>Top Courses</Link>
                  </li>
                  <li className='flex gap-1 items-center'>
                    <BiChevronsRight size={25} className='fill-ascent' />
                    <Link to={'/register'} className='text-white text-sm leading-loose hover:text-ascent transition duration-300 ease-in-out hover:ease-in-out'>Register as Student</Link>
                  </li>
                  <li className='flex gap-1 items-center'>
                    <BiChevronsRight size={25} className='fill-ascent' />
                    <a href='https://app-development.padhaidekho.com/institute/register' className='text-white text-sm leading-loose hover:text-ascent transition duration-300 ease-in-out hover:ease-in-out'>Register as Institute</a>
                  </li>
                  <li className='flex gap-1 items-center'>
                    <BiChevronsRight size={25} className='fill-ascent' />
                    <Link to={'/shipping-policy'} className='text-white text-sm leading-loose hover:text-ascent transition duration-300 ease-in-out hover:ease-in-out'>Register as Faculty</Link>
                  </li>
                </ul>
              </div>

              <div className='space-y-4 lg:pl-10'>
                <h6 className='font-semibold text-xl text-white'>Information</h6>
                <ul className='space-y-2'>
                  <li className='flex gap-1 items-center'>
                    <BiChevronsRight size={25} className='fill-ascent' />
                    <Link to={'/privacy-policy'} className='text-white text-sm leading-loose hover:text-ascent transition duration-300 ease-in-out hover:ease-in-out'>Privacy Policy</Link>
                  </li>
                  <li className='flex gap-1 items-center'>
                    <BiChevronsRight size={25} className='fill-ascent' />
                    <Link to={'/terms-conditions'} className='text-white text-sm leading-loose hover:text-ascent transition duration-300 ease-in-out hover:ease-in-out'>Terms & Conditions</Link>
                  </li>
                  <li className='flex gap-1 items-center'>
                    <BiChevronsRight size={25} className='fill-ascent' />
                    <Link to={'/refund-policy'} className='text-white text-sm leading-loose hover:text-ascent transition duration-300 ease-in-out hover:ease-in-out'>About us</Link>
                  </li>
                  <li className='flex gap-1 items-center'>
                    <BiChevronsRight size={25} className='fill-ascent' />
                    <Link to={'/shipping-policy'} className='text-white text-sm leading-loose hover:text-ascent transition duration-300 ease-in-out hover:ease-in-out'>Customer Support</Link>
                  </li>
                  <li className='flex gap-1 items-center'>
                    <BiChevronsRight size={25} className='fill-ascent' />
                    <Link to={'/terms-conditions'} className='text-white text-sm leading-loose hover:text-ascent transition duration-300 ease-in-out hover:ease-in-out'>Blogs & Updates</Link>
                  </li>
                  <li className='flex gap-1 items-center'>
                    <BiChevronsRight size={25} className='fill-ascent' />
                    <Link to={'/refund-policy'} className='text-white text-sm leading-loose hover:text-ascent transition duration-300 ease-in-out hover:ease-in-out'>Frequenty Asked Questions</Link>
                  </li>
                </ul>
              </div>

              <div className='space-y-5'>
                <div className='space-y-4'>
                  <h6 className='font-semibold text-xl text-white'>Newsletter</h6>
                  <p className='text-sm text-gray-300 leading-loose'>Signup for our weekly newsletter to get the latest news.</p>
                  <form className='flex gap-2' onSubmit={(event) => handleNewsletterEmailForm(event)}>
                    <input type="email" value={email} className='py-2.5 border border-gray-700 px-4 bg-gray-800 text-sm w-full text-white outline-none rounded-lg' placeholder='Email Address' required minLength={10} maxLength={100} onChange={(event) => setEmail(event.target.value)} />
                    <button type='submit' className='btn-primary-sm'>Submit</button>
                  </form>
                </div>
                <div className='space-y-4'>
                  <h6 className='font-semibold text-xl text-white'>Social Media</h6>
                  <p className='text-sm text-gray-300 leading-loose'>Follow our social media accounts</p>
                  <ul className='flex items-center justify-start space-x-5'>
                    <li><a href="#"><FaFacebookSquare className='fill-ascent' size={20} /></a></li>
                    <li><a href="#"><FaInstagram className='fill-ascent' size={20} /></a></li>
                    <li><a href="#"><FaTwitter className='fill-ascent' size={20} /></a></li>
                    <li><a href="#"><FaLinkedin className='fill-ascent' size={20} /></a></li>
                  </ul>
                </div>
              </div>


            </div>
          </div>
        </nav>
        <nav className='bg-black py-4'>
          <div className='container'>
            <div className='flex lg:flex-row sm:flex-col items-center justify-between gap-5'>
              <div className='text-center'>
                <p className='text-gray-400 text-sm leading-loose'>Copyrights © 2023 ZappDeal All Rights Reserved</p>
              </div>
              <div className='text-center'>
                <p className='text-gray-400 text-sm leading-loose'>Designed & Developed By <a href="https://tcongsinfotech.com" className='text-gray-100'>TcongsInfotech</a></p>
              </div>
            </div>
          </div>
        </nav>
      </footer>
    </React.Fragment>
  );
}

export default Footer;
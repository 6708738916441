import React, { useState } from 'react';
import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { getAllPost } from '../../../services/user/post';
import { toast } from 'sonner';
import { FiCalendar, FiEdit, FiHeart, FiMapPin } from 'react-icons/fi';

const PostCard = ({ post }) => {
  return (
    <React.Fragment>
      <figure className='shadow-md border rounded-lg overflow-clip cursor-pointer'>
        <Link to={`/dashboard/post/${post.id}`}>
          <div className='relative'>
            <div className='lg:h-[170px] sm:h-[170px] w-full overflow-clip flex items-center justify-center'>
              <img src={`${process.env.REACT_APP_STORAGE_BASE_URL}/${post.thumbnail_image}`} alt="" />
            </div>
          </div>
        </Link>
        <div className='lg:p-3 md:p-3 sm:p-2 space-y-3'>
          <div className='space-y-1'>
            <Link to={`/dashboard/post/${post.id}`}>
            <h1 className='text-sm font-semibold whitespace-normal'>{post.title}</h1>
            </Link>
            <div className='flex items-center justify-start gap-2'>
              <p className='text-ascent font-semibold text-xl'>{process.env.REACT_APP_CURRENCY_SYMBOL + parseInt(post.selling_price).toLocaleString('en-IN')}</p>
              { (post.is_negotiable === "1") ? <span className='lg:text-[0.6rem] sm:text-[0.5rem] px-2 py-0.5 bg-slate-100 rounded-lg'>Negotiable</span> : null }
            </div>
            <div className='space-y-1'>
              <p className='text-[0.7rem] leading-relaxed text-gray-600 flex items-center gap-1'>
                <FiMapPin strokeWidth={2.2} /> 
                <span>{post.address.short_address}</span>
              </p>
              <p className='text-[0.7rem] leading-relaxed text-gray-600 flex items-center gap-1'>
                <FiCalendar strokeWidth={2.2} /> 
                <span>Posted {post.posted_on.ago}</span>
              </p>
            </div>
            <div className='py-1'>
              <Link to={`/dashboard/post/${post.id}`}>
              <button className='btn-light-sm w-full flex items-center justify-center gap-2'>
                <FiEdit/>
                <span>Edit Post</span>
              </button>
              </Link>
            </div>
          </div>
        </div>
      </figure>
    </React.Fragment>
  )
}

const Posts = () => {

  const auth = useSelector((state) => state.AuthReducer);

  const [posts, setPosts] = useState([]);

  const fetchPosts = async () => {
    const response = await getAllPost();
    if (response.status) {
      setPosts(await response.data);
    }
    else {
      toast.warning(response.message);
    }
  }

  useEffect(() => {
    fetchPosts();
  },[]);
 
  if (!auth) {
    return "Loading";
  }

  return (
    <React.Fragment>
      <div>
        <div className='grid lg:grid-cols-3 md:grid-cols-2 sm:grid-cols-1 gap-3'>
          {posts.map((post) => <PostCard key={post.id} post={post} />)}
        </div>
      </div>
    </React.Fragment>
  )
}

export default Posts
import { CHAT_COUNT_SET, CHAT_COUNT_UNSET } from "../constants/ChatCountConstant";

export const setChatCount = (data) => {
    return {
        type: CHAT_COUNT_SET,
        payload: data
    }
};

export const unsetChatCount = () => {
    return {
        type: CHAT_COUNT_UNSET,
        payload: 0
    }
};
import React, { useEffect, useRef } from 'react';
import "../styles/app.css";
import 'react-toastify/dist/ReactToastify.css';
import 'react-loading-skeleton/dist/skeleton.css'
import { Toaster } from 'sonner';
import GuestRoutes from '../routes/GuestRoutes';
import LoadingBar from 'react-top-loading-bar'
import { useLocation } from 'react-router-dom';

const App = () => {

  const ref = useRef(null)

  const { pathname } = useLocation();

  useEffect(() => {
    ref.current.staticStart();
    setTimeout(() => {
      ref.current.complete()
    }, 300);
  },[pathname]);

  return (
    <React.Fragment>
      <React.StrictMode>
        <GuestRoutes />
        <Toaster position="top-center" duration={2000} richColors />
        <LoadingBar color='#f58834' ref={ref} />
      </React.StrictMode>
    </React.Fragment>
  )
}

export default App;
import React, { useEffect, useState } from 'react'
import Breadcrumbs from '../../components/Breadcrumbs/Breadcrumbs'
import { useNavigate, useParams } from 'react-router-dom'
import { getBrandsByCategory, getModelsByBrand, getSpecificationsByCategory, getVariantsByModel } from '../../services/guest/category'
import { FiArrowRight, FiSearch, FiUploadCloud } from 'react-icons/fi';
import { MdMyLocation } from 'react-icons/md';
import { LuLoader } from "react-icons/lu";
import { createPost } from '../../services/user/post';
import { toast } from 'sonner';
import { LoadScript, Autocomplete } from '@react-google-maps/api';

const libraries = ['places'];

const PostItemDetails = () => {

  const { category_id } = useParams();

  const navigate = useNavigate();

  const [selectBoxInputs, setSelectBoxInputs] = useState([]);
  const [radioInputs, setRadioInputs] = useState([]);
  const [otherInputs, setOtherInputs] = useState([]);
  const [selectedImages, setSelectedImages] = useState([]);
  const [selectedThumbnail, setSelectedThumbnail] = useState('/assets/default-thumbnail.png');
  const [formSubmitState, setFormSubmitState] = useState(false);

  const [brands, setBrands] = useState([]);
  const [models, setModels] = useState([]);
  const [variants, setVariants] = useState([]);

  const location = JSON.parse(localStorage.getItem('location'));

  const [locationInputs, setLocationInputs] = useState({
    usingCurrentLocation: true,
    latitude: location?.latitude,
    longitude: location?.longitude,
    locality: location?.locality,
    city: location?.city,
    postalCode: location?.postalCode,
    state: location?.state,
    country: location?.country,
    placeId: location?.placeId,
  });

  const fetchCategory = async () => {
    const specificationResponse = await getSpecificationsByCategory(category_id);
    if (specificationResponse.status) {
      const selectBoxInputsData = await specificationResponse.data.filter((item) => {
        if (item.input_type === 'select') {
          return item;
        }
      });
      setSelectBoxInputs(selectBoxInputsData);
      const radioInputsData = await specificationResponse.data.filter((item) => {
        if (item.input_type === 'radio') {
          return item;
        }
      });
      setRadioInputs(radioInputsData);
      const otherInputsData = await specificationResponse.data.filter((item) => {
        if (item.input_type !== 'radio' && item.input_type !== 'select') {
          return item;
        }
      });
      setOtherInputs(otherInputsData);
    }
  }

  const fetchBrands = async () => {
    const brandsResponse = await getBrandsByCategory(category_id);
    if (brandsResponse.status) {
      setBrands(await brandsResponse.data);
    }
  }

  const fetchModels = async (brandId) => {
    setModels([]);
    setVariants([]);
    const modelsResponse = await getModelsByBrand(brandId);
    if (modelsResponse.status) {
      setModels(await modelsResponse.data);
    }
  }

  const fetchVariants = async (modelId) => {
    setVariants([]);
    const variantssResponse = await getVariantsByModel(modelId);
    if (variantssResponse.status) {
      setVariants(await variantssResponse.data);
    }
  }

  const handleSelectImages = (event) => {
    const files = event.target.files;
    const filesArray = Array.from(files);
    const filesJson = filesArray.map(file => { return file });
    if (files.length > 0) {
      setSelectedImages(filesJson);
    }
    else {
      setSelectedImages([]);
    }
  }

  useEffect(() => {
    fetchCategory();
    fetchBrands();
  }, []);

  const handleFormSubmit = async (event) => {
    setFormSubmitState(true);
    event.preventDefault();

    const otherData = Object.fromEntries(
      new FormData(event.target)
    );

    if(otherData['brand']) {
      const brand = brands.find((item) => item.id === otherData['brand']);
      otherData['brand'] = brand.name
    }

    if(otherData['model']) {
      const model = models.find((item) => item.id === otherData['model']);
      otherData['model'] = model?.name
    }

    if(otherData['variant']) {
      const variant = variants.find((item) => item.id === otherData['variant']);
      otherData['variant'] = variant.name
    }

    delete otherData['title'];
    delete otherData['description'];
    delete otherData['images'];
    delete otherData['remember'];
    delete otherData['is_negotiable'];
    delete otherData['selling_price'];

    const form = new FormData(event.target);

    const data = {
      category_id: category_id,
      title: form.get('title'),
      description: form.get('description'),
      thumbnail_image: form.get('thumbnail_image'),
      post_media: form.getAll('post_media'),
      metadata: otherData,
      selling_price: form.get('selling_price'),
      is_negotiable: form.get('is_negotiable') ? 1 : 0,
      is_current_address: locationInputs?.usingCurrentLocation ? 1 : 0,
      latitude: locationInputs?.latitude,
      longitude: locationInputs?.longitude,
      locality: locationInputs?.locality,
      city: locationInputs?.city,
      postal_code: locationInputs?.postalCode,
      state: locationInputs?.state,
      country: locationInputs?.country,
      map_place_id: locationInputs?.placeId,
    }

    const postResponse = await createPost(data);
    const result = await postResponse;
    if (result.status) {
      toast.success(result.message);
      navigate('/dashboard/account');
    }
    else {
      setFormSubmitState(false);
      toast.error(result.message);
    }    
  }

  const [autocomplete, setAutocomplete] = useState(null);

  const onLoad = (autocompleteInstance) => {
    setAutocomplete(autocompleteInstance);
  };

  const onPlaceChanged = () => {

    let cityName = null;
    let countryName = null;
    let localityName = null;
    let postalCode = null;
    let state = null;
    let placeId = null;

    if (autocomplete !== null) {
      const place = autocomplete.getPlace();

      placeId = place?.place_id;
      
      if (place.address_components) {

        const addressComponents = place.address_components;

        for (let j = 0; j < addressComponents.length; j++) {
          const types = addressComponents[j].types;
          if (types.includes('locality')) {
            cityName = addressComponents[j].long_name;
          }
          if (types.includes('country')) {
            countryName = addressComponents[j].long_name;
          }
          if (types.includes('sublocality')) {
            localityName = addressComponents[j].long_name;
          }
          if (types.includes('postal_code')) {
            postalCode = addressComponents[j].long_name;
          }
          if (types.includes('administrative_area_level_1')) {
            state = addressComponents[j].long_name;
          }
        }

        setLocationInputs({ ...locationInputs, 
          ['usingCurrentLocation']: false,
          ['locality']: localityName, 
          ['city']: cityName, 
          ['postalCode']: postalCode, 
          ['state']: state, 
          ['country']: countryName,
          ['placeId']: placeId,
         });
      }
      else {
        setLocationInputs({ ...locationInputs, 
          ['usingCurrentLocation']: false,
          ['locality']: localityName, 
          ['city']: cityName, 
          ['postalCode']: postalCode, 
          ['state']: state, 
          ['country']: countryName,
          ['placeId']: placeId,
         });
      }
    } else {
      setLocationInputs({ ...locationInputs, 
        ['usingCurrentLocation']: false,
        ['locality']: localityName, 
        ['city']: cityName, 
        ['postalCode']: postalCode, 
        ['state']: state, 
        ['country']: countryName,
        ['placeId']: placeId,
       });
    }
  };

  return (
    <React.Fragment>
      <Breadcrumbs crumbs={[, {
        label: 'Post Your Product or Service',
        path: '/post-item',
        active: false
      }]} />
      <section>
        <div className='container lg:py-10 md:py-7 sm:py-5 space-y-5'>

          <div className='mb-10 space-y-7'>
            <div className='space-y-1'>
              <h1 className='text-xl font-semibold'>Add Information</h1>
              <p className='text-xs text-gray-600'>Please fill the required details</p>
            </div>
            <div>

              <form onSubmit={(event) => handleFormSubmit(event)} encType='multipart/form-data'>

                <div className='lg:w-6/12 md:w-8/12 sm:w-full lg:border lg:p-5 lg:shadow-sm rounded-md'>
                  <div className='grid grid-cols-1 gap-5'>

                    <div>
                      <h2 className='font-semibold'>General Details</h2>
                    </div>

                    {selectBoxInputs.map((item, index) => {
                      return (item.fetch_type === 'Brand' && brands.length > 0) && <div className='input-group' key={index}>
                        <label htmlFor="brand" className='text-sm font-medium mb-2'>Brand { item.input_is_required && <em>*</em>} </label>
                        <select onChange={(event) => fetchModels(event.target.value)} name="brand" id="brand" className='input-box-md' required={item.input_is_required}>
                          <option value="">Select Brand</option>
                          {brands.map((brand) => <option key={brand.id} value={brand.id}>{brand.name}</option>)}
                        </select>
                      </div>
                    })}

                    {selectBoxInputs.map((item, index) => {
                      return (item.fetch_type === 'Model' && models.length > 0) && <div className='input-group' key={index}>
                        <label htmlFor="model" className='text-sm font-medium mb-2'>Model { item.input_is_required && <em>*</em>} </label>
                        <select onChange={(event) => fetchVariants(event.target.value)} name="model" id="model" className='input-box-md' required={item.input_is_required}>
                          <option value="">Select Model</option>
                          {models.map((model) => <option key={model.id} value={model.id}>{model.name}</option>)}
                        </select>
                      </div>
                    })}

                    {selectBoxInputs.map((item, index) => {
                      return (item.fetch_type === 'Variant' && variants.length > 0) && <div className='input-group' key={index}>
                        <label htmlFor="variant" className='text-sm font-medium mb-2'>Variant { item.input_is_required && <em>*</em>} </label>
                        <select name="variant" id="variant" className='input-box-md' required={item.input_is_required}>
                          <option value="">Select Model</option>
                          {variants.map((variant) => <option key={variant.id} value={variant.id}>{variant.name}</option>)}
                        </select>
                      </div>
                    })}

                    {radioInputs.map((item, index) => {
                      return <div className='flex flex-col space-y-2' key={index}>
                        <div>
                          <label className='text-sm font-medium'>{item.name} { item.input_is_required && <em>*</em>} </label>
                        </div>
                        <div className='flex flex-wrap gap-2' key={index}>
                          {item.options.map((option, optionIndex) => <div className='relative' key={optionIndex}>
                            <input className="sr-only peer" type="radio" name={item.name} id={`${item.name}_${optionIndex}`} value={option} required={item.input_is_required} />
                            <label className="flex px-5 py-2 border border-gray-300 rounded-md bg-ascent bg-opacity-0 cursor-pointer focus:outline-none peer-checked:ring-ascent peer-checked:ring-2 peer-checked:border-transparent peer-checked:bg-opacity-10 text-xs font-medium" htmlFor={`${item.name}_${optionIndex}`}>{option}</label>
                          </div>)}
                        </div>
                      </div>
                    })}

                    {otherInputs.map((item, index) => {
                      return <div className='input-group' key={index}>
                        <label htmlFor={item.name} className='text-sm font-medium mb-2'>{item.name} { item.input_is_required && <em>*</em>} </label>
                        <input placeholder={item.input_placeholder} type={item.input_type} className='input-box-md w-full' name={item.name} required={item.input_is_required} />
                      </div>
                    })}

                    <div className='input-group'>
                      <label htmlFor="title" className='text-sm font-medium mb-2'>Title <em>*</em></label>
                      <input type="text" placeholder='Post Title' className='input-box-md' name="title" required={true} />
                    </div>

                    <div className='input-group'>
                      <label htmlFor="description" className='text-sm font-medium mb-2'>Description <em>*</em></label>
                      <textarea name="description" rows={3} id="description" className='input-box-md' placeholder='Describe your product'></textarea>
                    </div>

                    <div className='input-group'>
                      <label htmlFor="selling_price" className='text-sm font-medium mb-2'>Selling Price <em>*</em></label>
                      <input type="number" min={1} max={1000000000} placeholder='Selling Price' className='input-box-md' name="selling_price" required={true} />
                    </div>

                    <div className="flex items-center">
                        <input type="checkbox" name="is_negotiable" value={1} id="is_negotiable" />
                        <label htmlFor="is_negotiable" className="text-xs">Price is Negotiable</label>
                    </div>

                    <div>
                      <hr />
                    </div>

                    <div>
                      <h2 className='font-semibold'>Upload Photos</h2>
                    </div>

                    <div className='input-group'>
                        <label htmlFor="images" className="input-label">Post Thumbnail <span>(Format: png, jpg, jpeg,
                            webp)</span> <em>*</em></label>

                        <div className="flex lg:flex-row sm:flex-col my-2 gap-2">
                          <div className="input-box-dragable">
                            <input type="file" accept="image/jpeg, image/jpg, image/png, image/webp" name="thumbnail_image" onChange={(event) => {
                              if (event.target.files.length > 0) {
                                setSelectedThumbnail(URL.createObjectURL(event.target.files[0]));
                              }
                              else {
                                setSelectedThumbnail('/assets/default-thumbnail.png');
                              }
                            }} required max={20} min={1} />
                            <FiUploadCloud size={30} />
                            <span>Darg and Drop Image Files</span>
                          </div>
                          <img src={selectedThumbnail} alt="thumbnail_image" className="input-thumbnail-preview" />
                        </div>
                    </div>

                    <div className='input-group'>
                        <label htmlFor="post_media" className="input-label">Select Images <span>(Format: png, jpg, jpeg,
                            webp)</span> <span>(Optional)</span></label>
                        <div className="input-box-dragable">
                          <input multiple type="file" accept="image/jpeg, image/jpg, image/png, image/webp" name="post_media" onChange={(event) => handleSelectImages(event)} required max={20} min={1} />
                          <FiUploadCloud size={30} />
                          <span>Darg and Drop Image Files</span>
                        </div>
                        <div className='grid lg:grid-cols-4 sm:grid-cols-3 gap-3 mt-3'>
                          {selectedImages.map((image, index) => 
                            <div key={index} className='h-[120px] w-full rounded border bg-slate-50 flex items-center justify-center overflow-clip'>
                              <img src={URL.createObjectURL(image)} alt="" className='h-auto w-full'  />
                            </div>
                          )}
                        </div>
                    </div>

                    <div>
                      <hr />
                    </div>

                    <div>
                      <h2 className='font-semibold'>Location Details</h2>
                    </div>

                    <div className='space-y-4'>
                      <div className='grid grid-cols-2 gap-2'>
                          <div className='relative'>
                            <input className="sr-only peer" type="radio" id="current_location" checked={locationInputs.usingCurrentLocation} onChange={() => setLocationInputs({ ...locationInputs, ['usingCurrentLocation']: true })} />
                            <label className="flex px-3 py-3 border border-gray-300 rounded-md bg-ascent bg-opacity-0 cursor-pointer focus:outline-none peer-checked:ring-ascent peer-checked:ring-2 peer-checked:border-transparent peer-checked:bg-opacity-10 text-sm font-medium" htmlFor="current_location">
                              <div className='flex items-center justify-center gap-2'>
                                <MdMyLocation size={16} />
                                <span>Current Location</span>
                              </div>
                            </label>
                          </div>

                          <div className='relative'>
                            <input className="sr-only peer" type="radio" id="search_location" checked={!locationInputs.usingCurrentLocation} onChange={() => setLocationInputs({ ...locationInputs, ['usingCurrentLocation']: false})} />
                            <label className="flex px-3 py-3 border border-gray-300 rounded-md bg-ascent bg-opacity-0 cursor-pointer focus:outline-none peer-checked:ring-ascent peer-checked:ring-2 peer-checked:border-transparent peer-checked:bg-opacity-10 text-sm font-medium" htmlFor="search_location">
                              <div className='flex items-center justify-center gap-2'>
                                <FiSearch size={16} />
                                <span>Search Location</span>
                              </div>
                            </label>
                          </div>

                      </div>
                      { !locationInputs.usingCurrentLocation && <div className='input-group'>
                        <label htmlFor="title" className='input-label'>Search Location</label>
                        <LoadScript googleMapsApiKey={process.env.REACT_APP_GOOGLE_MAPS_API_KEY} libraries={libraries} >
                          <Autocomplete onLoad={onLoad} onPlaceChanged={onPlaceChanged} types={['(regions)']}>
                            <input type="text" placeholder="Search Location" className='input-box-md w-full' required />
                          </Autocomplete>
                        </LoadScript>
                      </div> }
                    </div>

                    <div>
                      <hr />
                    </div>

                    <div className="flex items-center">
                        <input type="checkbox" name="remember" id="remember" required />
                        <label htmlFor="remember" className="text-xs">I accept the terms & conditions</label>
                    </div>
                    <div>
                      <button disabled={formSubmitState} type='submit' className='btn-primary-md flex items-center justify-center gap-2 w-full'>
                        {formSubmitState && <LuLoader size={15} className='animate-spin' />}
                        <span>Submit Post</span>
                        <FiArrowRight strokeWidth={3} size={15} />
                      </button>
                    </div>


                  </div>
                </div>

              </form>



            </div>
          </div>

        </div>
      </section>
    </React.Fragment>
  )
}

export default PostItemDetails

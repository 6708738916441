import React, { useState } from 'react';
import { FiKey, FiSend } from 'react-icons/fi';
import { Link, useNavigate } from 'react-router-dom';
import { resetPassword, sendPasswordResetOTP } from '../../../services/user/auth';
import { toast } from 'sonner';
import Breadcrumbs from '../../../components/Breadcrumbs/Breadcrumbs';

const ForgotPassword = () => {

  const navigate = useNavigate();

  const [isOtpSent, setIsOtpSentState] = useState(false);

  const [inputFields, setInpuFields] = useState({
    email: '',
    password: '',
    password_confirmation: '',
    otp: '',
    token: ''
  });

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setInpuFields({ ...inputFields, [name]: value });
  }

  const handleFormSubmit = async (event) => {
    event.preventDefault();
    if (isOtpSent) {
      const result = await resetPassword(inputFields);
      if (result.status) {
        toast.success(result.message);
        navigate('/login');
      }
      else {
        toast.error(result.message);
      }
    }
    else {
      const result = await sendPasswordResetOTP({
        email: inputFields.email
      });
      if (result.status) {
        setIsOtpSentState(true);
        setInpuFields({ ...inputFields, ['token']: result.data.token });
        toast.success(result.message);
      }
      else {
        toast.error(result.message);
      }
    }
  }

  return (
    <React.Fragment>
      <section>
        <div className='grid lg:grid-cols-2 md:grid-cols-2 sm:grid-cols-1'>
          <div className='lg:block md:block sm:hidden h-full bg-cover bg-center' style={{ backgroundImage: "linear-gradient(rgba(0,0,0,0.3),rgba(0,0,0,0.3)),url('/assets/Home/intro-bg.jpg')" }}>
            <div className='flex items-center justify-center h-full lg:w-7/12 md:w-10/12 sm:w-full mx-auto'>
              <div className='space-y-2'>
                <div className='space-y-3'>
                  <h1 className='text-5xl font-bold text-white'>ZappDeal</h1>
                  <p className='text-gray-200 text-sm'>Lorem, ipsum dolor sit amet consectetur adipisicing dicta commodi hic laboriosam harum voluptatibus iste.</p>
                </div>
              </div>
            </div>
          </div>
          <div className='flex items-center justify-center'>
            <div className='lg:py-20 md:py-16 sm:py-5 lg:px-0 md:px-0 sm:px-3 lg:w-7/12 md:w-10/12 sm:w-full'>

              <div>

              <figure>
              <form className='lg:p-10 md:p-8 sm:p-7 space-y-5' onSubmit={(event) => handleFormSubmit(event)}>

                <div className='text-center mt-3'>
                  <h1 className='font-semibold lg:text-3xl sm:text-2xl mb-2'>Forgot Password</h1>
                  <p className='text-xs text-slate-600 mb-6'>Please fill the credentials to reset password</p>
                </div>

                {!isOtpSent && <div className='input-group'>
                  <label htmlFor="email" className='input-label'>Email Address <em>*</em></label>
                  <input onChange={(event) => handleInputChange(event)} value={inputFields.email} type="email" name='email' className='input-box-md' placeholder='Email Address' required minLength={10} maxLength={50} />
                </div>}

                {isOtpSent &&
                  <>
                    <div className='input-group'>
                      <label htmlFor="otp" className='input-label'>Verification OTP <em>*</em></label>
                      <input onChange={(event) => handleInputChange(event)} value={inputFields.otp} type="number" name='otp' className='input-box-md' placeholder='OTP (6 Digits)' required pattern='[0-9]{6}' />
                    </div>

                    <div className='input-group'>
                      <label htmlFor="password" className='input-label'>Password <em>*</em></label>
                      <input onChange={(event) => handleInputChange(event)} value={inputFields.password} type="password" name='password' className='input-box-md' placeholder='Password' required minLength={6} maxLength={20} />
                    </div>

                    <div className='input-group'>
                      <label htmlFor="password_confirmation" className='input-label'>Confirm Password <em>*</em></label>
                      <input onChange={(event) => handleInputChange(event)} value={inputFields.password_confirmation} type="password" name='password_confirmation' className='input-box-md' placeholder='Repeat Password' required minLength={6} maxLength={20} />
                    </div> </>}

                <div>
                  <button type='submit' className='btn-primary-sm w-full'>
                    <div className='flex items-center justify-center relative'>
                      {isOtpSent ? <>
                        <FiKey className='absolute left-0' size={15} />
                        <span>Reset Password</span>
                      </> : <>
                        <FiSend className='absolute left-0' size={15} />
                        <span>Send Verification OTP</span></>
                      }
                    </div>
                  </button>
                </div>

                <div className="text-center space-y-2">
                  <div>
                    <p className="text-slate-600 text-xs">I remember my password ? <Link to={'/login'} className="font-medium text-xs text-ascent hover:text-ascent-dark">Login now</Link></p>
                  </div>
                </div>

              </form>
            </figure>

              </div>

            </div>
          </div>
        </div>
      </section>
    </React.Fragment>
  )
}

export default ForgotPassword

import React, { useEffect, useState, Fragment } from 'react'
import { IoCheckmarkDoneOutline, IoCheckmarkOutline } from "react-icons/io5";
import { FiChevronDown, FiChevronLeft, FiExternalLink, FiFile, FiImage, FiMapPin, FiPaperclip, FiPhoneCall, FiSearch, FiSend, FiTrash, FiTrash2, FiUser } from 'react-icons/fi';
import { deleteConversationChat, getIndividualConversation, sendConversationChat } from '../../services/user/conversation';
import { Link, useOutletContext, useParams } from 'react-router-dom';
import { toast } from 'sonner';
import { Menu, Transition, Popover } from '@headlessui/react'
import Swal from 'sweetalert2';
import moment from 'moment';
import { useRef } from 'react';

const ConversationPreview = () => {

  const Message = ({ data, isDeleted }) => {

    const handleDeleteChat = async () => {
      Swal.fire({
        title: 'Are you sure?',
        text: 'Once you clicked on delete this message will deleted',
        icon: 'question',
        showCancelButton: true,
        cancelButtonText: "Cancel",
        confirmButtonText: "Delete Message",
      }).then(async (result) => {
        if (result.isConfirmed) {
          const response = await deleteConversationChat(data.id);
          if (response.status) {
            toast.success(response.message);
            fetchConversation();
          }
          else {
            toast.error(response.message)
          }
        }
      });
    }

    return (
      <React.Fragment>
        <div className={`flex items-center ${data.method == "SENT" ? 'justify-end' : 'justify-start'}`}>
          <div className={`flex flex-col bg-opacity-20 py-1.5 px-2 gap-1 w-fit lg:max-w-[300px] md:max-w-[350px] sm:max-w-[260px] ${data.method == "SENT" ? 'bg-ascent text-right' : 'bg-slate-400 text-left'} rounded-lg ${data.method == "SENT" ? 'rounded-br-none' : 'rounded-bl-none'}`}>
            {isDeleted
              ?
              <div className='flex items-center justify-center gap-0.5 opacity-80 italic'>
                <FiTrash2 size={12} className='mt-[-1px]' /><h6 className='text-xs font-medium'>This message is deleted</h6>
              </div>
              :
              <div className='flex flex-col gap-1'>
                {data.type === "MESSAGE" && <div>
                  <h6 className='text-[0.8rem] font-medium'>{data.message}</h6>
                </div>}
                {data.type === "MEDIA" && <div className='space-y-1'>
                  <img src={`${process.env.REACT_APP_STORAGE_BASE_URL}/${data?.media?.path}`} className='rounded' alt={data?.media?.filename} />
                  {data.message && <h6 className='text-[0.8rem] font-medium'>{data.message}</h6>}
                </div>}
                {data.type === "LOCATION" && <div>
                  <iframe className='w-full' src={`https://maps.google.com/maps?q=${data?.location?.latitude},${data?.location?.longitude}&t=&z=15&ie=UTF8&iwloc=&output=embed`} />
                </div>}
                <div className={`flex items-center gap-0.5 ${data.method == "SENT" ? 'justify-end' : 'justify-start'}`}>
                  <div className={`flex items-center gap-0.5`}>
                    {(data.method === "SENT") && ((data.time.checked_at === null) ? <IoCheckmarkOutline size={14} /> : <IoCheckmarkDoneOutline size={14} />)}
                    <h6 className='text-[0.6rem]'>{moment(data.time.sent_at).format('D/M/YYYY, h:mm A')}</h6>
                  </div>
                  {data.method == "SENT" &&
                    <Menu as="div" className="relative mb-[-5px]">
                      <Menu.Button><FiChevronDown strokeWidth={2} size={17} /></Menu.Button>
                      <Transition
                        as={Fragment}
                        enter="transition ease-out duration-100"
                        enterFrom="transform opacity-0 scale-95"
                        enterTo="transform opacity-100 scale-100"
                        leave="transition ease-in duration-75"
                        leaveFrom="transform opacity-100 scale-100"
                        leaveTo="transform opacity-0 scale-95"
                      >
                        <Menu.Items className="absolute z-10 right-0 w-fit origin-top-right rounded-md bg-white shadow border">
                          <button onClick={() => handleDeleteChat()} className='px-4 py-3'>
                            <div className='flex items-center justify-center gap-1.5 text-xs font-medium text-red-500 hover:text-red-700'>
                              <FiTrash strokeWidth={2.5} className='mt-[-1px]' />
                              <span className='text-nowrap'>Delete Message</span>
                            </div>
                          </button>
                        </Menu.Items>
                      </Transition>
                    </Menu>}

                </div></div>}
          </div>
        </div>
      </React.Fragment>
    )
  }

  const { id } = useParams();

  const chatsDiv = useRef(null);

  const mediaFileInput = useRef(null);

  const { setChatSyncCount, chatSyncCount } = useOutletContext();

  const initialMssageSuggestions = [
    "Hi, is this still available ?",
    "Hello! I’m interested in this !",
    "Hi! Is the price negotiable ? ",
  ]

  const [conversation, setConversation] = useState(null);
  const [conversationChats, setConversationChats] = useState([]);
  const [selectedImages, setSelectedImages] = useState([]);

  const handleSelectImages = (event) => {
    const files = event.target.files;
    const filesArray = Array.from(files);
    const filesJson = filesArray.map(file => { return file });
    if (files.length > 0) {
      setSelectedImages(filesJson);
    }
    else {
      setSelectedImages([]);
    }
  }

  const fetchConversation = async () => {
    const response = await getIndividualConversation(id);
    if (response.status) {
      setConversation(await response.data.conversation);
      setConversationChats(await response.data.chats);
      console.log(response.data.conversation);
    }
    else {
      toast.error(response.message)
    }
  }

  const handleChatSend = async (event) => {
    event.preventDefault();
    const form = new FormData(event.target);
    let type = null;
    if (form.get('media').name !== "") {
      type = "MEDIA";
    }
    else {
      type = "MESSAGE";
    }
    const response = await sendConversationChat({
      conversation_id: conversation?.id,
      type: type,
      message: form.get('message'),
      media: form.get('media') ? form.get('media') : null
    });
    if (response.status) {
      fetchConversation();
      setSelectedImages([]);
      event.target.reset();
      setChatSyncCount(chatSyncCount + 1);
    }
    else {
      toast.error(response.message)
    }
  }

  const handleSendQuichMessage = async (message) => {
    const response = await sendConversationChat({
      conversation_id: conversation?.id,
      type: "MESSAGE",
      message: message,
    });
    if (response.status) {
      fetchConversation();
      setChatSyncCount(chatSyncCount + 1);
    }
    else {
      toast.error(response.message)
    }
  }

  const handleLocationSend = async (event) => {
    if ("geolocation" in navigator) {
      navigator.geolocation.getCurrentPosition(
        async (position) => {
          const response = await sendConversationChat({
            conversation_id: conversation?.id,
            type: "LOCATION",
            location_latitude: position.coords.latitude,
            location_longitude: position.coords.longitude
          });
          if (response.status) {
            fetchConversation();
            setChatSyncCount(chatSyncCount + 1);
          }
          else {
            toast.error(response.message)
          }
        },
        (error) => {
          toast.error("Allow location access");
        }
      );
    } else {
      toast.error("Allow location access");
    }
  }

  useEffect(() => {
    fetchConversation();
  }, [id]);

  useEffect(() => {
    if (chatsDiv.current) {
      chatsDiv.current.scrollTop = chatsDiv.current.scrollHeight;
    }
  });

  if (!conversation) {
    return "Loading";
  }

  return (
    <React.Fragment>
      <figure>
        <div className='p-3 lg:pl-3 md:pl-3 sm:pl-0 border-b shadow relative'>
          <div className='flex items-center justify-between'>
            <div className='flex items-center justify-start gap-2'>
              <div className='flex items-center justify-start'>
                <div className='lg:hidden md:hidden sm:block'>
                  <Link to={'/chats'} className='p-1 flex items-center text-gray-700'>
                    <button><FiChevronLeft strokeWidth={2.5} size={32} /></button></Link>
                </div>
                <div className='relative w-fit h-fit'>
                  <div className='h-[44.5px] w-[44px] rounded-md overflow-clip flex items-center justify-center border'>
                    <img src={`${process.env.REACT_APP_STORAGE_BASE_URL}/${conversation?.post?.thumbnail_image}`} className='h-full w-full' alt="" />
                  </div>
                  <div className='h-[22px] w-[22px] rounded-full overflow-clip flex items-center justify-center border absolute -bottom-1 -right-1'>
                    <img src={`${process.env.REACT_APP_STORAGE_BASE_URL}/${conversation?.post?.user?.profile_image}`} className='h-full w-full' alt="" />
                  </div>
                  {(conversation?.post?.posted_by_me == true) && <div className='px-1 py-0.5 rounded-sm bg-green-600 absolute -top-1 -left-1 text-[0.5rem] text-white'>
                    My Post
                  </div>}
                </div>
              </div>
              <div className='w-full space-y-0.5'>
                <h5 className='text-sm font-semibold'>{conversation?.post?.title}</h5>
                <h6 className='text-xs text-gray-500 font-medium'>{conversation?.post?.user?.name}</h6>
              </div>
            </div>
            <div className='flex items-center justify-end gap-4 pr-2'>
              <Link target='_blank' to={`/post/${conversation?.post?.id}`}>
                <button title='View Post Details'>
                  <FiExternalLink size={20} strokeWidth={2} />
                </button>
              </Link>
              <button title='View Post Details'>
                <FiUser size={20} strokeWidth={2} />
              </button>
            </div>
          </div>
          <div className='absolute top-full left-3 z-[2]'>
            <div className='py-2 px-2.5 bg-white shadow border rounded-b-md'>
              <div className='flex items-center justify-start gap-2'>
              <p className='text-xs font-medium'>Selling at {process.env.REACT_APP_CURRENCY_SYMBOL + parseInt(conversation?.post?.selling_price).toLocaleString('en-IN')}</p>
              { (conversation?.post?.is_negotiable == "1") ? <span className='text-[0.5rem] px-2 py-0.5 bg-slate-100 rounded-lg'>Negotiable</span> : null }
            </div>
              {}
            </div>
          </div>
        </div>
        <div>
          <div>
            <div className='lg:h-[400px] md:h-[400px] sm:h-[70vh] overflow-y-scroll flex flex-col gap-2 p-3 w-full pt-12' ref={chatsDiv}>

              {(conversation?.conversation_status === "CONVERSATION_INITATED") && <div className='h-full flex items-center justify-center'>
                <div className='space-y-3 text-center flex flex-col items-center justify-center'>
                  <img src="/assets/start-chat.png" alt="start-chat" className='h-[200px] w-auto' />
                  <div className='space-y-1'>
                    <h1 className='font-semibold'>Start Conversation with {conversation?.post?.user?.name}</h1>
                    <p className='text-gray-700 text-xs px-3 py-2 bg-gray-50 rounded-full w-fit mx-auto'>For {conversation?.post?.title}</p>
                  </div>
                </div>
              </div>}

              {conversationChats.map((chat) => <Message key={chat.id} isDeleted={chat.is_deleted} data={chat} />)}
            </div>
          </div>
          <div className='p-2 border-t shadow-[0px_0px_3px_rgba(0,0,0,0.2)]'>
            {selectedImages.length > 0 && <div className='pb-2 border-b mb-2'>
              <div className='grid lg:grid-cols-5 sm:grid-cols-3 gap-3'>
                {selectedImages.map((image, index) =>
                  <div key={index} className='h-[100px] w-full rounded border bg-slate-50 flex items-center justify-center overflow-clip'>
                    <img src={URL.createObjectURL(image)} alt="" className='h-auto w-full' />
                  </div>
                )}
              </div>
            </div>}
            <div>
              {(conversation?.conversation_status === "CONVERSATION_INITATED") && <div>
                <div className='flex flex-col gap-2 mb-2.5'>
                  <p className='text-xs text-gray-600'>Send a quick message</p>
                  <div className='flex flex-wrap gap-2'>
                    {initialMssageSuggestions.map((message, index) => 
                    <button key={index} onClick={() => handleSendQuichMessage(message)} className='py-2 px-3 text-xs font-medium bg-gray-100 rounded-lg'>{message}</button>)}
                  </div>
                </div>
              </div>}
              <form onSubmit={(event) => handleChatSend(event)} className='flex items-center justify-start gap-2 w-full'>

                <div className='flex items-center justify-start rounded-md bg-slate-100 w-full px-2 py-1'>
                  <div>
                    <Popover className="relative">
                      {({ open }) => (
                        <React.Fragment>
                          <Popover.Button>
                            <button type='button' className={`p-2 hover:bg-slate-300 ${open ? 'bg-slate-300' : 'bg-transparent'} rounded-md`}>
                              <FiPaperclip size={15} className='stroke-slate-600' />
                            </button>
                          </Popover.Button>
                          <Transition
                            as={Fragment}
                            enter="transition ease-out duration-200"
                            enterFrom="opacity-0 translate-y-1"
                            enterTo="opacity-100 translate-y-0"
                            leave="transition ease-in duration-150"
                            leaveFrom="opacity-100 translate-y-0"
                            leaveTo="opacity-0 translate-y-1"
                          >
                            <Popover.Panel className="absolute bottom-full z-10 mb-5 w-fit">
                              <div className="shadow-lg bg-white border rounded-md">
                                <div className='p-2 space-y-2'>
                                  <button onClick={() => mediaFileInput.current.click()} type='button' className='bg-slate-100 p-2 rounded-md flex items-center justify-start w-full gap-1'>
                                    <FiImage size={16} className='mt-[-0.5px]' />
                                    <span className='text-nowrap text-xs font-medium'>Share Photos</span>
                                  </button>
                                  <button onClick={() => handleLocationSend()} type='button' className='bg-slate-100 p-2 rounded-md flex items-center justify-start w-full gap-1'>
                                    <FiMapPin size={15} />
                                    <span className='text-nowrap text-xs font-medium'>Share Current Location</span>
                                  </button>
                                </div>
                              </div>
                            </Popover.Panel>
                          </Transition>
                        </React.Fragment>
                      )}
                    </Popover>
                  </div>
                  <input onChange={(event) => handleSelectImages(event)} accept="image/jpeg, image/jpg, image/png, image/webp" type="file" hidden name='media' ref={mediaFileInput} />
                  <input type="text" name='message' className='bg-transparent p-2 outline-none w-full text-sm' placeholder='Type Your Message' minLength={1} maxLength={100000} />
                </div>
                <button type='submit' className='p-3 bg-ascent rounded-md'>
                  <FiSend size={20} className='stroke-white' />
                </button>
              </form>

            </div>
          </div>
        </div>
      </figure>
    </React.Fragment>
  )
}


export default ConversationPreview

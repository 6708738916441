import { LOCATION_SET, LOCATION_UNSET } from "../constants/LocationConstant";

export const setLocation = (data) => {
    return {
        type: LOCATION_SET,
        payload: data
    }
};

export const unSetLocation = () => {
    return {
        type: LOCATION_UNSET,
        payload: null
    }
};
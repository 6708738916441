import axios from 'axios';
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { userDetailsUpdate } from '../../../services/user/user';
import { AuthUserLogin } from '../../../redux/actions/AuthAction';
import { toast } from 'sonner';
import { BiLogOut } from 'react-icons/bi'
import Swal from 'sweetalert2'

const Setting = () => {

  const auth = useSelector((state) => state.AuthReducer);


  const dispatch = useDispatch();

  const genders = [
    {
      label: 'Male',
      value: 'MALE'
    },
    {
      label: 'Female',
      value: 'FEMALE'
    },
    {
      label: 'Other',
      value: 'OTHER'
    }
  ];

  const [inputFields, setInputFields] = useState({
    name: '',
    date_of_birth: '',
    gender: '',
    profession: '',
  });

  useEffect(() => {
    if (auth) {
      setInputFields({
        name: auth.name,
        date_of_birth: auth.date_of_birth,
        gender: auth.gender,
        profession: auth.profession,
      });
    }
  }, [auth]);

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setInputFields({ ...inputFields, [name]: value });
  }

  const handleProfileChange = (event) => {
    setInputFields({ ...inputFields, ['profile']: event.target.files[0] });
  }

  const handleFormSubmit = async (event) => {
    event.preventDefault();
    const result = await userDetailsUpdate(inputFields);
    if (result.status) {
      toast.success(result.message);
      dispatch(AuthUserLogin(result.data));
    }
    else {
      toast.error(result.message);
    }
  }

  const handleLogout = () => {
    Swal.fire({
      title: 'Are you sure?',
      text: 'Once you clicked on logout you will logged out',
      icon: 'question',
      showCancelButton: true,
      cancelButtonText: "Cancel",
      confirmButtonText: "Logout",
    }).then((result) => {
      if (result.isConfirmed) {
        localStorage.removeItem('accessToken');
        window.location = "/";
      }
    });
  }

  if (!auth) {
    return "Loading";
  }

  return (
    <React.Fragment>
      <form onSubmit={(event) => handleFormSubmit(event)}>
        <figure className='border shadow rounded-lg overflow-clip'>
          <div className='px-5 py-4 space-y-1 border-b'>
            <h1 className='text-base font-semibold'>Account Settings</h1>
            <p className='text-xs text-gray-500'>Manage your account information</p>
          </div>
          <div className='p-5 space-y-1'>
            <div className='grid lg:grid-cols-3 md:grid-cols-2 sm:grid-cols-1 gap-5'>

              <div className='lg:col-span-3 md:col-span-2 sm:col-span-1'>
                <h1 className='font-semibold'>General Information</h1>
              </div>

              {/* Name */}
              <div className="input-group">
                <label htmlFor="name" className='input-label'>Your Name <em>*</em></label>
                <input type="text" name='name' className='input-box-md' placeholder='Enter Name' value={inputFields.name} required minLength={5} maxLength={100} onChange={(event) => handleInputChange(event)} />
              </div>

              {/* Profession */}
              <div className="input-group">
                <label htmlFor="profession" className='input-label'>Profession <em>*</em></label>
                <input type="text" name='profession' className='input-box-md' placeholder='Enter Profession' value={inputFields.profession} required minLength={5} maxLength={100} onChange={(event) => handleInputChange(event)} />
              </div>

              {/* Date of Birth */}
              <div className="input-group">
                <label htmlFor="date_of_birth" className='input-label'>Date of Birth <span>(Optional)</span></label>
                <input type="date" name='date_of_birth' className='input-box-md' value={inputFields.date_of_birth} onChange={(event) => handleInputChange(event)} />
              </div>

              {/* Gender */}
              <div className="input-group">
                <label htmlFor="gender" className='input-label'>Gender <span>(Optional)</span></label>
                <div className='flex gap-3 mt-3'>
                  {genders.map(({ label, value }) => (
                    <div className='input-radio' key={value}>
                      <input type="radio" name='gender' id={`gender_${value}`} value={value} checked={value == inputFields.gender} onChange={(event) => handleInputChange(event)} />
                      <label htmlFor={`gender_${value}`}>{label}</label>
                    </div>
                  ))}
                </div>
              </div>

            </div>
          </div>
          <div className='p-5 space-y-1 border-t'>
            <button type='submit' className='btn-primary-sm md:w-fit sm:w-full'>Save Changes</button>
          </div>
        </figure>
      </form>

      <figure className='border shadow rounded-lg overflow-clip mt-5 lg:hidden md:hidden sm:block'>
        <div className='p-5'>
          <button onClick={() => handleLogout()} className='btn-light-md flex items-center justify-center gap-2 w-full'>
            <BiLogOut size={20} />
            <span>Logout</span>
          </button>
        </div>
      </figure>
    </React.Fragment>
  )
}

export default Setting
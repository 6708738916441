import React, { useEffect, useState } from 'react'
import { Swiper, SwiperSlide } from 'swiper/react'
import 'swiper/css';
import 'swiper/css/pagination';
import { Autoplay, Pagination } from 'swiper/modules';
import ProductCard from '../../../Cards/ProductCard/ProductCard';
import { getPosts } from '../../../../services/guest/post';

const Products = () => {

  const [posts, setPosts] = useState([]);

  const location = JSON.parse(localStorage.getItem('location'));

  const [limit, setLimit] = useState(20);

  const fetchPosts = async () => {
    const response = await getPosts({
      locality: location?.locality,
      postal_code: location?.postalCode,
      city: location?.city,
      country: location?.country,
      limit: limit
    });
    if (response.status) {
      setPosts(await response.data);
    }
  }

  useEffect(() => {
    fetchPosts();
  }, [limit]);

  return (
    <React.Fragment>
      <section>
        <div className='container lg:pt-20 md:pt-16 sm:pt-10 lg:pb-28 md:py-20 sm:py-16 lg:space-y-10 md:space-y-8 sm:space-y-6'>

          <div className='lg:w-6/12 md:w-8/12 sm:w-full mx-auto text-center space-y-2'>
            <h1 className='text-3xl font-semibold text-slate-800'>Recent Products</h1>
            <hr className='w-[100px] mx-auto border border-ascent-dark' />
            <p className='lg:text-sm sm:text-xs text-gray-500'>Lorem ipsum dolor sit amet consectetur adipisicing elit. Doloremque nemo ipsa nostrum eius laudantium asperiores, fugiat reprehenderit debitis</p>
          </div>

          <div className='grid lg:grid-cols-4 md:grid-cols-3 sm:grid-cols-2 gap-2'>

            {posts.map((post) => <ProductCard key={post.id} post={post} /> )}

            <div className='lg:col-span-4 md:col-span-3 sm:col-span-2 text-center'>
              <button className='btn-secondary-md mt-5' onClick={() => setLimit(limit + 10)}>Load more posts</button>
            </div>
            
          </div>

        </div>
      </section>
    </React.Fragment>
  )
}

export default Products

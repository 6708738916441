import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Outlet, useNavigate } from 'react-router-dom';

const Auth = () => {

  const navigate = useNavigate();

  const auth = useSelector((state) => state.AuthReducer);
  
  useEffect(() => {
    if (!localStorage.getItem('accessToken')) {
      navigate('/login');
    }
  },[auth]);

  if (!auth) {
    return "Loading...";
  }

  return <Outlet />
}

export default Auth;
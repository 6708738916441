import React, { useState } from 'react'
import { FiArrowRight, FiCalendar, FiExternalLink, FiHeart, FiMapPin, FiPhoneCall } from 'react-icons/fi';
import { Link } from 'react-router-dom';
import { saveWishlist } from '../../../services/user/wishlist';
import { toast } from 'sonner';
import { useSelector } from 'react-redux';

const ProductCard = ({ post }) => {

  const [wishlistState, setWishlistState] = useState(post.is_in_wishlist);

  const handleSaveWishlist = async () => {
    const response = await saveWishlist(post.id);
    if (await response.status) {
      toast.success(await response.message);
      setWishlistState(!wishlistState);
    }
  }

  const auth = useSelector((state) => state.AuthReducer);

  return (
    <React.Fragment>
      <figure className='shadow-md border rounded-lg overflow-clip cursor-pointer'>
          <div className='relative border-b'>
            {(auth) ? <button onClick={() => handleSaveWishlist()} className={`absolute lg:top-5 lg:right-5 sm:top-3 sm:right-3 ${wishlistState ? 'bg-pink-500' : 'bg-white'} rounded-full p-2 shadow-sm border ${wishlistState ? 'text-white' : 'text-pink-600'}  hover:text-white hover:bg-pink-500 transition duration-300 ease-in-out hover:ease-in-out active:scale-105`}>
              <FiHeart />
            </button> : <Link to={'/login'} className='absolute lg:top-5 lg:right-5 sm:top-3 sm:right-3 bg-white rounded-full p-2 shadow-sm border text-pink-600 hover:text-white hover:bg-pink-500 transition duration-300 ease-in-out hover:ease-in-out'>
              <FiHeart />
            </Link> }
            <Link to={`/post/${post.id}`}>
            <div className='lg:h-[170px] sm:h-[120px] w-full overflow-clip flex items-center justify-center'>
              <img src={`${process.env.REACT_APP_STORAGE_BASE_URL}/${post.thumbnail_image}`} alt="" />
            </div>
            </Link>
          </div>
        <div className='lg:p-3 md:p-3 sm:p-2 space-y-3'>
          <div className='space-y-1'>
          <Link to={`/post/${post.id}`}>
            <h1 className='lg:text-sm md:text-sm sm:text-xs font-semibold whitespace-normal'>{post.title}</h1>
            </Link>
            <div className='flex items-center justify-start gap-2'>
              <p className='text-ascent font-semibold lg:text-xl md:text-lg sm:text-lg'>{process.env.REACT_APP_CURRENCY_SYMBOL + parseInt(post.selling_price).toLocaleString('en-IN')}</p>
              { (post.is_negotiable === "1") ? <span className='lg:text-[0.6rem] sm:text-[0.5rem] px-2 py-0.5 bg-slate-100 rounded-lg'>Negotiable</span> : null }
            </div>
            <div className='space-y-1'>
              <p className='lg:text-[0.7rem] sm:text-[0.6rem] leading-relaxed text-gray-600 flex items-center gap-1'>
                <FiMapPin strokeWidth={2.2} /> 
                <span>{post.address.short_address}</span>
              </p>
              <p className='lg:text-[0.7rem] sm:text-[0.6rem] leading-relaxed text-gray-600 flex items-center gap-1'>
                <FiCalendar strokeWidth={2.2} /> 
                <span>Posted {post.posted_on.ago}</span>
              </p>
            </div>
          </div>
        </div>
      </figure>
    </React.Fragment>
  )
}

export default ProductCard

import axios from "axios";

export const initiateConversation = async (data) => {
    try {
        
        const response = axios.post(`${process.env.REACT_APP_API_BASE_URL}/user/conversation/create`, data, {
            headers: {
              "Content-Type": 'multipart/form-data',
              "Authorization": `Bearer ${localStorage.getItem('accessToken')}`
            }
        });
        return (await response).data;
        
    } catch (error) {
        return false;
    }
}

export const sendConversationChat = async (data) => {
    try {
        
        const response = axios.post(`${process.env.REACT_APP_API_BASE_URL}/user/conversation/chat/create`, data, {
            headers: {
              "Content-Type": 'multipart/form-data',
              "Authorization": `Bearer ${localStorage.getItem('accessToken')}`
            }
        });
        return (await response).data;
        
    } catch (error) {
        return false;
    }
}

export const getIndividualConversation = async (id) => {
    try {
        
        const response = axios.post(`${process.env.REACT_APP_API_BASE_URL}/user/conversation/details/${id}`, {

        }, {
            headers: {
              "Content-Type": 'multipart/form-data',
              "Authorization": `Bearer ${localStorage.getItem('accessToken')}`
            }
        });
        return (await response).data;
        
    } catch (error) {
        return false;
    }
}

export const getAllConversation = async (data) => {
    try {
        
        const response = axios.post(`${process.env.REACT_APP_API_BASE_URL}/user/conversation`, data, {
            headers: {
              "Content-Type": 'multipart/form-data',
              "Authorization": `Bearer ${localStorage.getItem('accessToken')}`
            }
        });
        return (await response).data;
        
    } catch (error) {
        return false;
    }
}

export const deleteConversationChat = async (id) => {
    try {
        
        const response = axios.delete(`${process.env.REACT_APP_API_BASE_URL}/user/conversation/chat/delete/${id}`, {
            headers: {
              "Content-Type": 'multipart/form-data',
              "Authorization": `Bearer ${localStorage.getItem('accessToken')}`
            }
        });
        return (await response).data;
        
    } catch (error) {
        return false;
    }
}


export const getUnreadMessageCount = async () => {
    try {
        
        const response = axios.get(`${process.env.REACT_APP_API_BASE_URL}/user/conversation/unread/message/count`, {
            headers: {
              "Content-Type": 'multipart/form-data',
              "Authorization": `Bearer ${localStorage.getItem('accessToken')}`
            }
        });
        return (await response).data;
        
    } catch (error) {
        return false;
    }
}

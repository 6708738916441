import axios from "axios"

export const getTopCategories = async () => {
    try {
        
        const response = axios.get(`${process.env.REACT_APP_API_BASE_URL}/category?limit=5&parent_category=1`);
        return (await response).data;
        
    } catch (error) {
        return false;
    }
}

export const getCategories = async () => {
    try {
        
        const response = axios.get(`${process.env.REACT_APP_API_BASE_URL}/category`);
        return (await response).data;
        
    } catch (error) {
        return false;
    }
}

export const getChildCategories = async () => {
    try {
        
        const response = axios.get(`${process.env.REACT_APP_API_BASE_URL}/category?child_category=1`);
        return (await response).data;
        
    } catch (error) {
        return false;
    }
}

export const getIndividualCategory = async (slug) => {
    try {
        
        const response = axios.get(`${process.env.REACT_APP_API_BASE_URL}/category/${slug}`);
        return (await response).data;
        
    } catch (error) {
        return false;
    }
}

export const getSpecifications = async (category_id) => {
    try {
        
        const response = axios.get(`${process.env.REACT_APP_API_BASE_URL}/specification?category_id=${category_id}`);
        return (await response).data;
        
    } catch (error) {
        return false;
    }
}

export const getBrands = async (category_id) => {
    try {
        
        const response = axios.get(`${process.env.REACT_APP_API_BASE_URL}/brand?category_id=${category_id}`);
        return (await response).data;
        
    } catch (error) {
        return false;
    }
}

export const getModels = async (brand_id) => {
    try {
        
        const response = axios.get(`${process.env.REACT_APP_API_BASE_URL}/model?brand_id=${brand_id}`);
        return (await response).data;
        
    } catch (error) {
        return false;
    }
}

export const getSpecificationsByCategory = async (category_id) => {
    try {
        
        const response = axios.get(`${process.env.REACT_APP_API_BASE_URL}/filter/specification?category_id=${category_id}`);
        return (await response).data;
        
    } catch (error) {
        return false;
    }
}

export const getBrandsByCategory = async (category_id) => {
    try {
        
        const response = axios.get(`${process.env.REACT_APP_API_BASE_URL}/filter/brand?category_id=${category_id}`);
        return (await response).data;
        
    } catch (error) {
        return false;
    }
}

export const getModelsByBrand = async (brand_id) => {
    try {
        
        const response = axios.get(`${process.env.REACT_APP_API_BASE_URL}/filter/model?brand_id=${brand_id}`);
        return (await response).data;
        
    } catch (error) {
        return false;
    }
}

export const getVariantsByModel = async (model_id) => {
    try {
        
        const response = axios.get(`${process.env.REACT_APP_API_BASE_URL}/filter/variant?model_id=${model_id}`);
        return (await response).data;
        
    } catch (error) {
        return false;
    }
}
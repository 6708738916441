import React, { useEffect, useState } from 'react'
import Breadcrumbs from '../../components/Breadcrumbs/Breadcrumbs'
import FilterSidebar from '../../components/Common/FilterSidebar/FilterSidebar'
import { useParams } from 'react-router-dom'
import { getIndividualCategory } from '../../services/guest/category'
import ProductCard from '../../components/Cards/ProductCard/ProductCard'
import { getPosts } from '../../services/guest/post'

const Category = () => {

  const { slug } = useParams();

  const [category, setCategory] = useState(null);
  const [posts, setPosts] = useState([]);

  const location = JSON.parse(localStorage.getItem('location'));

  const fetchData = async () => {
    const data = await getIndividualCategory(slug);
    const responseCategory = await data.data;
    setCategory(responseCategory)
    const response = await getPosts({
      category_id: responseCategory?.id,
      postal_code: location?.postalCode,
      city: location?.city,
    });
    if (response.status) {
      setPosts(await response.data);
    }

  }

  
  useEffect(() => {
    fetchData();
  },[slug]);

  if (!category) {
    return "Loading";
  }

  return (
    <React.Fragment>
      <Breadcrumbs crumbs={[,{
        label: 'Categories',
        path: '/categories',
        active: false
      },{
        label: category.name,
        path: `/category/${category.slug}`,
        active: true
      }]} />
      <section>
        <div className='container py-10'>

          <div className='grid lg:grid-cols-4 md:grid-cols-2 sm:grid-cols-1 gap-5'>

            <div>
              { category && <FilterSidebar category={category} />  }
            </div>

            <div className='lg:col-span-3 md:col-span-2 sm:col-span-1'>
              <div className='grid lg:grid-cols-3 md:grid-cols-2 sm:grid-cols-2 gap-3'>
                {posts.map((post) => <ProductCard key={post.id} post={post} /> )}
              </div>
            </div>

          </div>

        </div>
      </section>
    </React.Fragment>
  )
}

export default Category

import React, { useEffect, useState } from 'react'
import Breadcrumbs from '../../components/Breadcrumbs/Breadcrumbs'
import FilterSidebar from '../../components/Common/FilterSidebar/FilterSidebar'
import { Link, useNavigate, useParams } from 'react-router-dom'
import { getCategories, getIndividualCategory } from '../../services/guest/category'
import { FiArrowLeft, FiArrowRight, FiChevronRight } from 'react-icons/fi'

const PostItemCategory = () => {

  const [categories, setCategories] = useState([]);

  const navigate = useNavigate();

  const fetchCategories = async () => {
    const data = await getCategories();
    setCategories(await data.data);
  }

  const [childCategories, setChildCategories] = useState([]);

  const [selectedItem, setSelectedItem] = useState({
    parentCategoryId: null
  });

  const handleSelectParentCategory = async (id) => {
    setSelectedItem({ ...selectedItem, ['parentCategoryId']: id });
    const selectedCategory = categories.filter((category) => {
      if (category.id === id) {
        return category;
      }
    });
    setChildCategories(selectedCategory[0].child_categories);
  }

  useEffect(() => {
    fetchCategories();
  }, []);

  return (
    <React.Fragment>
      <Breadcrumbs crumbs={[, {
        label: 'Post Your Product or Service',
        path: '/post-item',
        active: false
      }]} />
      <section>
        <div className='container lg:py-10 md:py-7 sm:py-5 space-y-5'>

          <div className='mb-10 space-y-7'>
            <div className='space-y-1'>
              <h1 className='text-xl font-semibold'>Categories</h1>
              <p className='text-xs text-gray-600'>Select the category of your product & service</p>
            </div>

            <div hidden={(childCategories.length !== 0)}>
              <div>
                <div className='grid lg:grid-cols-5 md:grid-cols-4 sm:grid-cols-3 gap-3'>
                  {categories.map((category) => <button
                    key={category.id}
                    onClick={() => {
                      if (category.child_categories.length === 0) {
                        navigate(`/post-item/details/${category.id}`);
                      }
                      else {
                        handleSelectParentCategory(category.id)
                      }
                    }}
                    className={`border rounded-xl shadow-sm ${(selectedItem.parentCategoryId == category.id) && 'ring-[2px] ring-ascent'}`}>
                    <div className='flex flex-col items-center justify-center p-5 gap-3 text-center'>
                      <img src={`${process.env.REACT_APP_STORAGE_BASE_URL}/${category.thumbnail_image}`} alt="img" className='h-[50px] w-auto' />
                      <p className='font-medium text-xs'>{category.name}</p>
                    </div>
                  </button>)}
                </div>
              </div>
            </div>

            <div hidden={(childCategories.length === 0)}>
              <div className='space-y-5'>
                <div>
                  <button onClick={() => {
                    setChildCategories([]);
                    setSelectedItem({
                      parentCategoryId: null
                    });
                  }} className='flex items-center justify-center w-fit px-3 py-1.5 bg-slate-100 gap-1 rounded'>
                    <FiArrowLeft size={15} />
                    <span className='text-[0.75rem]'>Back to Categories</span>
                  </button>
                </div>
                <ul className='flex flex-col gap-2'>
                  {childCategories.map((category) => <li key={category.id} className="border rounded-lg shadow-sm">
                      <Link to={`/post-item/details/${category.id}`} className='flex items-center justify-start px-3 py-3 gap-2 text-center'>
                        <FiChevronRight size={20} strokeWidth={3} />
                        <p className='font-medium text-base'>{category.name}</p>
                      </Link>
                  </li>)}
                </ul>
              </div>
            </div>

          </div>

        </div>
      </section>
    </React.Fragment>
  )
}

export default PostItemCategory

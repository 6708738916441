import React, { useEffect, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import Sidebar from '../Sidebar/Sidebar';
import $ from 'jquery';
import HeaderCitiesDropdown from '../../Dropdowns/HeaderCitiesDropdown/HeaderCitiesDropdown';
import { useSelector } from 'react-redux';
import { FiMessageCircle, FiSearch } from 'react-icons/fi';

const MobileHeader = () => {

  const [sidebarState, setSidebarState] = useState(false);

  const auth = useSelector((state) => state.AuthReducer);
  const chatCount = useSelector((state) => state.ChatCountReducer);

  const { pathname } = useLocation();

  useEffect(() => {
    setSidebarState(false);

    $(window).scroll(function () {
      if ($(window).scrollTop() > 100) {
        $('.top-header').slideUp(250);
      }
      else {
        $('.top-header').slideDown(250);
      }
    });

  }, [pathname]);

  return (
    <React.Fragment>
      <header className='fixed top-0 z-50 w-full lg:hidden md:hidden sm:block bg-white shadow-sm border-b' id='mobile-header'>
        {/* <nav className='bg-slate-900 top-header'>
          <div className='container py-2 text-center'>
            <div>
              <p className='text-xs text-white whitespace-nowrap'>Buy, sell and find just about anything using our mobile app</p>
            </div>
          </div>
        </nav> */}
        <nav>
          <div className='container py-3 space-y-2.5'>
            <div className='flex items-center justify-between'>
              <div>
                <Link to={'/'}>
                  <img src={'/assets/logo.png'} alt="logo" className='h-[27px] w-auto' />
                </Link>
              </div>
              <div className='flex gap-3 items-center'>
                <HeaderCitiesDropdown />
                {auth && <Link to={'/chats'} className='relative'><FiMessageCircle strokeWidth={2.2} size={25} />
                { (chatCount !== 0) && <span className='h-5 w-5 text-[0.6rem] flex items-center justify-center text-white font-medium bg-ascent rounded-full absolute -top-2 -right-2'>{chatCount}</span> }</Link> }
                <button onClick={() => setSidebarState(!sidebarState)} className={`mobile-sidebar-toggler ${sidebarState ? 'active' : null}`}>
                  <div />
                  <div />
                  <div />
                </button>
              </div>
            </div>
            <div className='border flex items-center justify-center rounded-md overflow-clip bg-slate-100 shadow-sm'>
                <input type="search" placeholder='Search Any Product' className='w-full px-3 py-2.5 outline-none text-xs' />
                <button className='px-4 py-2.5'><FiSearch size={15} /> </button>
              </div>
          </div>
        </nav>
      </header>

      <Sidebar state={sidebarState} />
    </React.Fragment>
  )
}

export default MobileHeader
import axios from "axios";


export const getPosts = async (data = {}) => {
    try {
        if (localStorage.getItem('accessToken')) {
            const response = axios.post(`${process.env.REACT_APP_API_BASE_URL}/post`, data, {
                headers: {
                  "Content-Type": 'multipart/form-data',
                  "Authorization": `Bearer ${localStorage.getItem('accessToken')}`
                }
            });
            return (await response).data;
        }
        else {
            const response = axios.post(`${process.env.REACT_APP_API_BASE_URL}/post`, data, {
                headers: {
                  "Content-Type": 'multipart/form-data'
                }
            });
            return (await response).data;
        }
    } catch (error) {
        return false;
    }
}

export const getIndividualPost = async (id) => {
    try {
        if (localStorage.getItem('accessToken')) {
            const response = axios.get(`${process.env.REACT_APP_API_BASE_URL}/post/${id}`, {
                headers: {
                  "Content-Type": 'multipart/form-data',
                  "Authorization": `Bearer ${localStorage.getItem('accessToken')}`
                }
            });
            return (await response).data;
        }
        else {
            const response = axios.get(`${process.env.REACT_APP_API_BASE_URL}/post/${id}`, {
                headers: {
                  "Content-Type": 'multipart/form-data',
                }
            });
            return (await response).data;
        }
    } catch (error) {
        return false;
    }
}
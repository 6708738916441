import React, { useEffect, useState } from 'react';
import Intro from '../../components/Sections/Home/Intro/Intro';
import Products from '../../components/Sections/Home/Products/Products';
import { getPosts } from '../../services/guest/post';

const Home = () => {
  return (
    <React.Fragment>
      <Intro/>
      <Products/>
    </React.Fragment>
  )
}

export default Home;
import React from 'react'
import { Link } from 'react-router-dom'
import { TbCirclePlus, TbClipboardList, TbHeart, TbHome2, TbUserCircle } from "react-icons/tb";
import { useSelector } from 'react-redux';

const MobileNavigation = () => {

  const auth = useSelector((state) => state.AuthReducer);

  return (
    <React.Fragment>
      <nav className='fixed w-full h-auto bottom-0 z-50 shadow-[0px_0px_15px_rgba(0,0,0,0.09)] bg-white border-t' id='mobile-navigation'>
        { auth ? <div className='grid grid-cols-7 gap-4'>
          <div className='grid grid-cols-2 col-span-3'>
            <Link to={'/'} className='mobile-navigation-tab'>
              <div>
                <TbHome2 size={29} />
                <span>Home</span>
              </div>
            </Link>
            <Link to={'/dashboard/post'} className='mobile-navigation-tab'>
              <div>
                <TbClipboardList size={29} />
                <span>My Posts</span>
              </div>
            </Link>
          </div>
          <div className='mobile-navigation-tab'>
            <div>
              <div className='flex items-center justify-center'>
                <Link to={'/post-item'} className='mt-[-36px] mb-[10px]'>
                  <div className='p-3 bg-gradient-to-br from-ascent to-ascent-dark text-white rounded-full ring-4 ring-white flex items-center justify-center gap-2 shadow-lg'>
                    <TbCirclePlus size={30} />
                    {/* <span className='whitespace-nowrap text-sm font-medium pr-1.5'>Sell Now</span> */}
                  </div>
                </Link>
              </div>
              <span className='whitespace-nowrap'>Start Selling</span>
            </div>
          </div>
          <div className='grid grid-cols-2 col-span-3'>
            <Link to={'/dashboard/wishlist'} className='mobile-navigation-tab'>
              <div>
                <TbHeart size={29} />
                <span>Favourites</span>
              </div>
            </Link>
            <Link to={'/dashboard/account'} className='mobile-navigation-tab'>
              <div>
                <TbUserCircle size={29} />
                <span>My Account</span>
              </div>
            </Link>
          </div>
        </div> : <div className='grid grid-cols-5 gap-4'>
          <div className='col-span-2'>
            <Link to={'/'} className='mobile-navigation-tab'>
              <div>
                <TbHome2 size={29} />
                <span>Home</span>
              </div>
            </Link>
          </div>
          <div className='mobile-navigation-tab'>
            <div>
              <div className='flex items-center justify-center'>
                <Link to={'/post-item'} className='mt-[-36px] mb-[10px]'>
                  <div className='p-3 bg-gradient-to-br from-ascent to-ascent-dark text-white rounded-full ring-4 ring-white flex items-center justify-center gap-2 shadow-lg'>
                    <TbCirclePlus size={30} />
                    {/* <span className='whitespace-nowrap text-sm font-medium pr-1.5'>Sell Now</span> */}
                  </div>
                </Link>
              </div>
              <span className='whitespace-nowrap'>Start Selling</span>
            </div>
          </div>
          <div className='col-span-2'>
            <Link to={'/dashboard/account'} className='mobile-navigation-tab'>
              <div>
                <TbUserCircle size={29} />
                <span>My Account</span>
              </div>
            </Link>
          </div>
        </div> }
      </nav>
    </React.Fragment>
  )
}

export default MobileNavigation

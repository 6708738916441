import React, { useState } from 'react';
import { LoadScript, Autocomplete } from '@react-google-maps/api';
import { setLocation } from '../../../redux/actions/LocationAction';
import { useDispatch, useSelector } from 'react-redux';

const libraries = ['places'];

const GooglePlacesAutocomplete = () => {
  const [autocomplete, setAutocomplete] = useState(null);
  const [city, setCity] = useState('');

  const location = useSelector((state) => state.LocationReducer);

  const dispatch = useDispatch();

  const onLoad = (autocompleteInstance) => {
    setAutocomplete(autocompleteInstance);
  };

  const onPlaceChanged = () => {

    let cityName = null;
    let countryName = null;
    let localityName = null;
    let postalCode = null;
    let state = null;

    if (autocomplete !== null) {
      const place = autocomplete.getPlace();

      if (place.address_components) {

        const addressComponents = place.address_components;

        for (let j = 0; j < addressComponents.length; j++) {
          const types = addressComponents[j].types;
          if (types.includes('locality')) {
            cityName = addressComponents[j].long_name;
          }
          if (types.includes('country')) {
            countryName = addressComponents[j].long_name;
          }
          if (types.includes('sublocality')) {
            localityName = addressComponents[j].long_name;
          }
          if (types.includes('postal_code')) {
            postalCode = addressComponents[j].long_name;
          }
          if (types.includes('administrative_area_level_1')) {
            state = addressComponents[j].long_name;
          }
        }

        localStorage.setItem('location', JSON.stringify({ ...location, 
          ['locality']: localityName, 
          ['city']: cityName, 
          ['postalCode']: postalCode, 
          ['state']: state, 
          ['country']: countryName,
        }));
        dispatch(setLocation({ ...location, 
          ['locality']: localityName, 
          ['city']: cityName, 
          ['postalCode']: postalCode, 
          ['state']: state, 
          ['country']: countryName,
         }));
      }
      else {
        localStorage.setItem('location', JSON.stringify({ ...location, 
          ['locality']: localityName, 
          ['city']: cityName, 
          ['postalCode']: postalCode, 
          ['state']: state, 
          ['country']: countryName,
        }));
        dispatch(setLocation({ ...location, 
          ['locality']: localityName, 
          ['city']: cityName, 
          ['postalCode']: postalCode, 
          ['state']: state, 
          ['country']: countryName,
         }));
      }
    } else {
      localStorage.setItem('location', JSON.stringify({ ...location, 
        ['locality']: localityName, 
        ['city']: cityName, 
        ['postalCode']: postalCode, 
        ['state']: state, 
        ['country']: countryName,
      }));
      dispatch(setLocation({ ...location, 
        ['locality']: localityName, 
        ['city']: cityName, 
        ['postalCode']: postalCode, 
        ['state']: state, 
        ['country']: countryName,
       }));
    }
  };

  return (
    <LoadScript googleMapsApiKey={process.env.REACT_APP_GOOGLE_MAPS_API_KEY} libraries={libraries} >
      <Autocomplete onLoad={onLoad} onPlaceChanged={onPlaceChanged} types={['(regions)']} className='border flex items-center justify-center rounded-lg overflow-clip bg-slate-100'>
        <input type="text" placeholder="Search City" className='w-full px-4 py-2 outline-none text-sm' />
      </Autocomplete>
    </LoadScript>
  );
};

export default GooglePlacesAutocomplete;

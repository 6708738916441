import React, { useState } from 'react';
import { FiArrowRight, FiKey } from 'react-icons/fi';
import { FcGoogle } from "react-icons/fc";
import { Link, useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { AuthUserLogin } from '../../../redux/actions/AuthAction';
import { userGoogleAuth, userLogin } from '../../../services/user/auth';
import { toast } from 'sonner';
import Breadcrumbs from '../../../components/Breadcrumbs/Breadcrumbs';
import { signInWithPopup } from 'firebase/auth';
import { auth, provider } from '../../../services/firebase';

const Login = () => {

  const navigate = useNavigate();

  const dispatch = useDispatch();

  const [inputFields, setInpuFields] = useState({
    email: '',
    password: '',
  });

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setInpuFields({ ...inputFields, [name]: value });
  }

  const handleFormSubmit = async (event) => {
    event.preventDefault();
    const result = await userLogin(inputFields);
    if (result.status) {
      localStorage.setItem('accessToken', result.data.access_token);
      dispatch(AuthUserLogin(result.data));
      toast.success(result.message);
      navigate('/dashboard/account');
    }
    else {
      toast.error(result.message);
    }
  }

  const handleGoogleSignup = () => {
    signInWithPopup(auth, provider).then(async (data) => {
      const {
        displayName,
        email,
        uid,
        photoURL
      } = data.user.providerData[0];
      const result = await userGoogleAuth({
        name: displayName,
        email: email,
        profile_url: photoURL,
        google_id: uid
      });
      if (result.status) {
        localStorage.setItem('accessToken', result.data.access_token);
        dispatch(AuthUserLogin(result.data));
        toast.success(result.message);
        navigate('/dashboard/account');
      }
      else {
        toast.error(result.message);
      }
    })
  }

  return (
    <React.Fragment>
      <section>
        <div className='grid lg:grid-cols-2 md:grid-cols-2 sm:grid-cols-1'>
          <div className='lg:block md:block sm:hidden h-full bg-cover bg-center' style={{ backgroundImage: "linear-gradient(rgba(0,0,0,0.3),rgba(0,0,0,0.3)),url('/assets/Home/intro-bg.jpg')" }}>
            <div className='flex items-center justify-center h-full lg:w-7/12 md:w-10/12 sm:w-full mx-auto'>
              <div className='space-y-2'>
                <div className='space-y-3'>
                  <h1 className='text-5xl font-bold text-white'>ZappDeal</h1>
                  <p className='text-gray-200 text-sm'>Lorem, ipsum dolor sit amet consectetur adipisicing dicta commodi hic laboriosam harum voluptatibus iste.</p>
                </div>
              </div>
            </div>
          </div>
          <div className='flex items-center justify-center'>
            <div className='lg:py-20 md:py-16 sm:py-5 lg:px-0 md:px-0 sm:px-3 lg:w-7/12 md:w-10/12 sm:w-full'>

              <div>

                <figure>
                  <form className='lg:p-10 md:p-8 sm:p-7 space-y-5' onSubmit={(event) => handleFormSubmit(event)}>

                    <div className='text-center mt-3'>
                      <h1 className='font-semibold lg:text-3xl sm:text-2xl mb-2'>Welcome Back</h1>
                      <p className='text-xs text-slate-600 mb-6'>Please fill the credentials to login</p>
                    </div>

                    <div className='input-group'>
                      <label htmlFor="email" className='input-label'>Email Address <em>*</em></label>
                      <input onChange={(event) => handleInputChange(event)} value={inputFields.email} type="email" name='email' className='input-box-md' placeholder='Email Address' required minLength={10} maxLength={50} />
                    </div>

                    <div className='input-group'>
                      <label htmlFor="password" className='input-label'>Password <em>*</em></label>
                      <input onChange={(event) => handleInputChange(event)} value={inputFields.password} type="password" name='password' className='input-box-md' placeholder='Password' required minLength={6} maxLength={20} />
                    </div>

                    <div>
                      <button type='submit' className='btn-primary-sm w-full'>
                        <div className='flex items-center justify-center relative'>
                          <FiKey className='absolute left-0' size={15} />
                          <span>Submit</span>
                        </div>
                      </button>
                    </div>

                    <div className="flex items-center justify-center space-x-3">
                      <hr className="w-full" />
                      <p className="text-gray-500 text-xs">OR</p>
                      <hr className="w-full" />
                    </div>

                    <div>
                      <button onClick={() => handleGoogleSignup()} type='button' className='bg-complement border border-complement text-xs font-medium  px-4 py-2.5 rounded-md w-full'>
                        <div className='flex items-center justify-center relative'>
                          <FcGoogle className='absolute left-0' size={15} />
                          <span className='text-gray-800'>Continue With Google</span>
                        </div>
                      </button>
                    </div>

                    <div className="text-center space-y-2">
                      <div>
                        <p className="text-slate-600 text-xs">Dosen't have an account? <Link to={'/register'} className="font-medium text-xs text-ascent hover:text-ascent-dark">Register now</Link></p>
                      </div>
                      <div>
                        <Link to={'/forgot-password'} className="font-medium text-xs text-ascent hover:text-ascent-dark">Forgot Password ?</Link>
                      </div>
                    </div>

                  </form>
                </figure>

              </div>

            </div>
          </div>
        </div>
      </section>
    </React.Fragment>
  )
}

export default Login

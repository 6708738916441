import React, { useState } from 'react'

import { FiChevronDown, FiExternalLink, FiPaperclip, FiPhoneCall, FiSearch, FiSend, FiUser } from 'react-icons/fi';
import { Link } from 'react-router-dom';

const ConversationEmpty = () => {

  return (
    <React.Fragment>
      <figure className='py-20 lg:block md:block sm:hidden'>
        <div className='w-full h-auto pb-10'>
          <div className='space-y-3'>
            <img src="/assets/empty-chat.png" alt="empty-chat" className='h-[160px] w-auto mx-auto' />
            <div className='space-y-1 text-center'>
              <h1 className='lg:text-2xl sm:text-xl font-semibold'>No Active Chat's!</h1>
              <p className='text-sm text-gray-500'>No chat selected, Please select a chat to continue.</p>
            </div>
            <div className='py-3 text-center'>
              <button>
                <Link to={'/'} className='btn-secondary-sm'>Continue Exploring</Link>
              </button>
            </div>
          </div>
        </div>
      </figure>
    </React.Fragment>
    
  )
}


export default ConversationEmpty
